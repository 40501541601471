import React, { useEffect } from 'react';
import OrderDoneimg from '../../asset/OrderDoneimg.png';

function OrderConfirm({ isModalOpen, onClose }) {
    useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'unset'; // Enable scrolling
    }

    // Clean up on unmount
    return () => {
      document.body.style.overflow = 'unset'; // Ensure scrolling is enabled when component unmounts
    };
  }, [isModalOpen]);
    if (!isModalOpen) return null;
  return (
    <div  className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={onClose}>
          <div className="relative p-8 bg-white rounded-lg shadow-lg w-[372px] h-[357px]">
              <div className='flex items-center justify-center mt-14'>
                
                   <img src={OrderDoneimg} alt="Wheat" className="w-[100px] h-[100px] mr-2 rounded-lg" />
              </div>
             <p className="font-lexend text-[26px] font-medium leading-[12px]  mt-10 text-center">
  Order Confirmed!
</p>

             
      </div>
    </div>
  )
}

export default OrderConfirm
