import React from 'react';

function Pagination({ currentPage, totalPages, onPageChange }) {
  const pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  return (
    <div className='flex justify-center gap-5 mt-5 mb-5'>
      {pages.map(page => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`h-[56px] w-[56px] rounded-full ${currentPage === page ? 'bg-[#4C3F35] text-white' : 'bg-[#FFF9F0] text-black'}`}
        >
          {page}
        </button>
      ))}
    </div>
  );
}

export default Pagination;
