import React, { useEffect } from 'react'
import { MdOutlineCancel } from "react-icons/md";

function CancelProduct({ isModalOpen, onClose }) {
     useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'unset'; // Enable scrolling
    }

    // Clean up on unmount
    return () => {
      document.body.style.overflow = 'unset'; // Ensure scrolling is enabled when component unmounts
    };
  }, [isModalOpen]);
    if (!isModalOpen) return null;
  return (
     <div  className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" >
          <div className="relative p-8 bg-white rounded-lg shadow-lg w-[372px] h-[450px]">
              <div className='flex justify-end '>
                  <MdOutlineCancel className='w-5 h-5 cursor-pointer' onClick={onClose}/>
              </div>
              <div>
                  <p className='text-center text-gray-800 text-[16px]'>OrderId : #123456</p>
              </div>
              <div className='mt-5'>
                  <div className="flex items-start gap-5 mt-5">
            <img src="https://via.placeholder.com/50" alt="Product" className="w-16 h-16"/>
            <div>
              <h3 className="font-semibold">Bhagar Seeds Organic</h3>
              <p className='text-[#6D6D6D]'>1 kg</p>
              <p className='text-[#6D6D6D]'>Quality: 1</p>
              <p className="text-green-500">₹150.00 </p>
            </div>
          </div>
              </div>
              <div className='mt-5'>
                  <input 
        type="text" 
        placeholder="Reason for cancellation" 
        className="w-full p-3 mb-4 border border-gray-300 rounded-lg"
      />
      
              </div>
              <div className='flex justify-center mt-5'>
                  <button className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600">
        Request to Cancel Order
      </button>
             </div>
      
    </div>
    </div>
  )
}

export default CancelProduct
