import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CartProvider } from './ContextApi/AddtocartContext';
import { WishlistProvider } from './ContextApi/Context';
import { OrderDetailsProvider } from './ContextApi/OrderDetailsContext';

import { PriceStatusProvider } from './ContextApi/PriceStatusContext';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CartProvider>
      <WishlistProvider>
        <OrderDetailsProvider>
          <PriceStatusProvider>
            <App />
          </PriceStatusProvider>

        </OrderDetailsProvider>

      </WishlistProvider>
    </CartProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
