import React, { createContext, useContext, useState } from 'react';
import { BaseUrl } from './Url/BaseUrl';

const OrderDetailsContext = createContext();

export const useOrderDetails = () => {
  return useContext(OrderDetailsContext);
};

export const OrderDetailsProvider = ({ children }) => {
  const [orderDetails, setOrderDetails] = useState({ products: [] });

  const fetchProducts = async (productIds) => {
    try {
      const response = await fetch(
        `${BaseUrl}/products/ProductDetailsForOrder`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ productIds }),
        }
      );

      const data = await response.json();

      if (data.result) {
        setOrderDetails((prev) => ({ ...prev, products: data.products }));
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const removeProduct = (productId) => {
  console.log("Removing product with ID:", productId);
  
  setOrderDetails((prev) => {
    console.log("Current products:", prev.products);
    const updatedProducts = prev.products.filter((product) => product._id !== productId);

    // Check if the updated products array is empty
    if (updatedProducts.length === 0) {
      // Reload the window if there are no products left
      window.location.reload();
    }

    return {
      ...prev,
      products: updatedProducts,
    };
  });
};





  return (
    <OrderDetailsContext.Provider value={{ orderDetails, fetchProducts, removeProduct }}>
      {children}
    </OrderDetailsContext.Provider>
  );
};
