import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AboutBg from '../../asset/AboutBg.png';
import Shop_Cards from '../../Componenet/Shop_Component/Shop_Cards';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';

function ShopPage() {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm || ''); // Get search term from state
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]); // State to hold categories

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${BaseUrl}/GetCommission-slab`);
        const data = await response.json();

        if (data.result) {
          const uniqueCategories = Array.from(new Set(data.CategoryList.map(category => category.category)))
            .map(category => {
              return data.CategoryList.find(cat => cat.category === category);
            });

          setCategories(uniqueCategories);
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  return (
    <div>
      {/* Header Section */}
      <div
        className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4"
        style={{ backgroundImage: `url(${AboutBg})` }}
      >
        <h1
          className="text-[#222222] text-[50px] md:text-5xl font-bold"
          style={{
            fontFamily: 'Lexend',
            fontWeight: 500,
            lineHeight: '1.2',
            textAlign: 'center', // Changed to center for better mobile experience
          }}
        >
          Shop
        </h1>
        <p className="mt-4 text-base text-center sm:text-lg">
          Home &gt;<span className="text-[#BE740A]"> Shop</span>
        </p>
      </div>

      {/* Search and Category Section */}
      <div className="px-4 md:px-[150px] mt-10 md:mt-20">
        <div className="flex flex-col md:flex-row w-full bg-[#FFF9F0] md:h-[68px] justify-between items-center px-4 py-3 md:px-6 rounded-lg mb-10 md:mb-20">
          <div className="w-full md:w-[840px] mb-4 md:mb-0">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full p-2 border border-gray-300 rounded-full"
            />
          </div>
          <div className="w-full md:w-[252px]">
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              className="w-full p-2 border border-gray-300 rounded-full"
            >
              <option value="">Show All</option>
              {categories.map((category) => (
                <option key={category._id} value={category.category}>
                  {category.category}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Shop Cards Section */}
      <div >
        <Shop_Cards searchTerm={searchTerm} selectedCategory={selectedCategory} />
      </div>
    </div>
  );
}

export default ShopPage;
