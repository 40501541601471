// PolicyModal.jsx
import React from 'react';

const PolicyModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-lg p-6 mx-4 bg-white rounded shadow-lg sm:max-w-md">
        <h2 className="mb-4 text-2xl font-bold text-center">Return Policy</h2>
        <p className='mt-5 text-sm sm:text-base'>
          <span className='font-bold'>1. Return Request Timing:</span>
          <br />
          You can only request a return before the product amount is paid. If the amount is
          paid or pending, no return requests will be accepted.
          <br />
          <span className='font-bold'>2. Product Condition:</span>
          <br />
          Returns are only accepted if the product is unused, unopened, and in its original
          packaging.
          <br />
          <span className='font-bold'>3. Non-Returnable Items:</span>
          <br />
          Opened, used, or planted seeds/millets, as well as bulk orders over 10kg, are not
          eligible for returns unless defective.
        </p>
        <div className='flex justify-center'>
          <button
            className="mt-4 w-full sm:w-[214px] h-[43px] rounded border bg-[#4C3F35] text-white"
            onClick={onClose}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default PolicyModal;
