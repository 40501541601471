import { BaseUrl } from "../Url/BaseUrl";



export const loginDealer = async (email, password) => {
    try {
        const response = await fetch(`${BaseUrl}/Dealer/dealerlogin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        });
        
        const data = await response.json();
        return data; // Return the response data for handling in the component
    } catch (error) {
        console.error('Error during API call:', error);
        throw error; // Propagate the error to handle it in the component
    }
};

export const registerDealer = async (dealerData) => {
    try {
        const response = await fetch(`${BaseUrl}/Dealer/AddDealer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dealerData),
        });

        // Check if the response status is OK (2xx)
        if (!response.ok) {
            // Parse the error response
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to register dealer');
        }

        // Parse and return the success response
        const data = await response.json();
        return data; // { result: true, statusCode: 201, message: "Dealer registered successfully" }
        
    } catch (error) {
        // Handle any network or server errors
        console.error('Error registering dealer:', error);
        return { result: false, message: error.message }; // Return error response format
    }
};
