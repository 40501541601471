import { useState, useEffect, useRef } from 'react';
import { FaRegUser } from 'react-icons/fa';
import { HiOutlineShoppingBag } from 'react-icons/hi';
import { IoMdLogOut } from 'react-icons/io';

const UserMenu = ({ dealerInfo, NavigateToMyOrders, NavigateToProfile, handleLogout, UserLogin }) => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const menuRef = useRef(null); // Reference to the menu

  const handleUserIconClick = () => {
    setShowUserMenu(!showUserMenu); // Toggle the user menu
  };

  const handleClickOutside = (event) => {
    // Close the menu if clicked outside
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowUserMenu(false);
    }
  };

  useEffect(() => {
    // Adding event listener to detect clicks outside the modal
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
    
    
    const HandleNaviagteToProfile = () => {
        setShowUserMenu(false);
        NavigateToProfile()
    }
    const HandleNavigateToMyOrders = () => {
         setShowUserMenu(false);
        NavigateToMyOrders()
    }
    

  return (
    <>
      {dealerInfo && (
        <>
          <span className="relative cursor-pointer" onClick={handleUserIconClick}>
            <FaRegUser className="h-[24px] w-[24px]" />
          </span>

          {showUserMenu && (
            <div ref={menuRef} className="absolute right-0 mt-10 bg-white shadow-lg w-[230px] h-auto rounded-lg">
              <div className="flex justify-center gap-2 p-4 font-bold">
                <img src={UserLogin} alt="User" className="w-[45px] h-[45px] mr-2 rounded-lg" />
                <div>
                  <p>{dealerInfo.shopName}</p>
                  <p className="text-[#6D6D6D]">
                    {dealerInfo.email?.length > 10
                      ? `${dealerInfo.email.substring(0, 10)}...`
                      : dealerInfo.email}
                  </p>
                </div>
              </div>
              <hr className="border-1" />
              <p
                className="flex justify-start gap-2 p-5 font-medium text-left cursor-pointer text-20px leading-12 hover:bg-gray-200"
                onClick={HandleNavigateToMyOrders}
              >
                <HiOutlineShoppingBag className="mt-1.5" />
                <span>My Orders</span>
              </p>
              <hr className="border-1" />
              <p
                className="flex justify-start gap-2 p-5 font-medium text-left cursor-pointer text-20px leading-12 hover:bg-gray-200"
                onClick={HandleNaviagteToProfile}
              >
                <FaRegUser className="mt-1.5" />
                <span>My Profile</span>
              </p>
              <hr className="border-1" />
              <p
                className="flex justify-start gap-2 p-5 font-medium text-left cursor-pointer text-20px leading-12 hover:bg-gray-200"
                onClick={handleLogout}
              >
                <IoMdLogOut className="mt-1.5" />
                <span className="text-red-500">Logout</span>
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserMenu;
