import React, { useEffect, useState } from 'react';
import Wheateimg from '../../asset/wheateImage.png';
import HomePageProducts from './HomePageProducts';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';

function OrganicProduct() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('ALL'); // Default selected category
  const [products, setProducts] = useState([]); // To store all products fetched

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${BaseUrl}/GetCommission-slab`);
        const data = await response.json();

        if (data.result) {
          const uniqueCategories = Array.from(new Set(data.CategoryList.map(item => item.category)));
          setCategories(uniqueCategories);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch(`${BaseUrl}/products/getEcommerceProducts`);
        const data = await response.json();

        if (data.result) {
          setProducts(data.productsList); // Assuming productsList contains the product data
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchCategories();
    fetchProducts();
  }, []);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const categoriesWithProducts = categories.filter(category =>
    products.some(product => product.category === category)
  );

  return (
    <div className='bg-[#FFF9F0] pt-16 md:pt-24 lg:pt-32'>
      {categoriesWithProducts.length > 0 ? (
        <>
          <div className='flex justify-center'>
            <p className="flex items-center text-[24px] text-[#4C3F35] md:text-[28px] lg:text-[32px]" style={{ fontFamily: 'Dancing Script, cursive', fontWeight: 700 }}>
              <img src={Wheateimg} alt="Wheat" className="w-[41.43px] h-[30px] mr-2" />
              Organic Products
            </p>
          </div>
          <div className='flex justify-center'>
            <p className="text-[20px] md:text-[36px] lg:text-[42px] font-bold leading-[50px] text-left" style={{ fontFamily: 'Lexend', fontWeight: 700 }}>
              Organic & Fresh Product Daily!
            </p>
          </div>

          <div className='flex flex-wrap justify-center gap-4 p-10'>
            <button 
              onClick={() => handleCategorySelect('ALL')} 
              className={`w-[84px] h-[44px] border rounded-lg hover:bg-[#735944] hover:text-white ${selectedCategory === 'ALL' ? 'bg-[#735944] text-white' : ''}`}
            >
              ALL
            </button>
            {categoriesWithProducts.slice(0, 7).map((category, index) => (
              <button 
                key={index} 
                onClick={() => handleCategorySelect(category)} 
                className={`w-[84px] h-[44px] border rounded-lg hover:bg-[#735944] hover:text-white ${selectedCategory === category ? 'bg-[#735944] text-white' : ''}`}
              >
                {category}
              </button>
            ))}
          </div>
          <div className='p-5 pb-10'>
            <HomePageProducts selectedCategory={selectedCategory} products={products} />
          </div>
        </>
      ) : (
        <p className='p-10 text-xl font-bold text-center'>No categories available at the moment.</p>
      )}
    </div>
  );
}

export default OrganicProduct;
