import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CartEmpty from '../../src/asset/CartEmpty.png';
import AddCartimg from '../../src/asset/AddCartimg.png';
import LoginRegisterModel from '../../src/Componenet/Models/LoginRegisterModel';
import { CartContext } from '../ContextApi/AddtocartContext';
import Swal from 'sweetalert2';

const DrawerModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { cart, removeFromCart } = useContext(CartContext);
  const [isModelOpen, setIsModelOpen] = useState(false);

  const NavigateToCartDetails = () => {
    onClose();
    navigate("/CartDetails");
  };

  const NavigateToAddDeliveryAddress = () => {
    const token = localStorage.getItem('authtoken');
    if (token) {
      const productsData = cart.map(item => ({
        productId: item._id,
        selection: item.selections
      }));

      navigate("/AddDeliveryAddress", { state: { productsData } });
      onClose();
    } else {
      Swal.fire({
        title: 'Please Login',
        text: 'You need to log in before proceeding.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const calculateDiscountedPrice = (price, discountPercentage) => {
    return price - (price * discountPercentage / 100);
  };

  const subtotal = cart.reduce((acc, item) => {
    const discountedPricePerKg = calculateDiscountedPrice(item.price, item.discount || 0);
    const itemTotal = item?.selections?.reduce((itemTotalAcc, selection) => {
      const sizeInKg = parseInt(selection.size);
      const totalPriceForSelection = discountedPricePerKg * sizeInKg * selection.quantity;
      return itemTotalAcc + totalPriceForSelection;
    }, 0);
    return acc + itemTotal;
  }, 0);

  return (
    <div className={`fixed inset-0 z-50 transition-transform transform ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="absolute top-0 right-0 h-full p-4 bg-white shadow-lg w-full sm:w-[431px] flex flex-col transition-all">
        <div className='flex justify-end mt-5'>
          <button className='w-[40px] h-[40px] p-2 rounded-full border border-black text-black' onClick={onClose}>
            X
          </button>
        </div>
        <div>
          <p className="font-lexend text-[24px] sm:text-[30px] font-bold text-left">Shopping cart</p>
          <div className='mt-2'>
            <hr className="border-t-2 border-[#4C3F35]" />
          </div>
        </div>

        <div className="flex-grow p-5 overflow-y-auto">
          {cart.length > 0 ? (
            cart.map(item => (
              <div key={item._id} className='flex flex-col items-center justify-between w-full p-2 mb-8 sm:flex-row'>
                <div className='flex gap-3'>
                  <img src={item.selectedImages[0] || AddCartimg} alt={item.productName} className="w-[78px] h-[78px] rounded-lg" />
                  <div className='mt-3'>
                    <div className='flex gap-5'>
                      <p className="font-dm-sans text-[14px] sm:text-[16px] font-bold leading-[20.83px] text-left text-black">
                        {item.productName.length > 15 ? `${item.productName.slice(0, 15)}...` : item.productName}
                      </p>
                      {item.discount && (
                        <button className='bg-[#735944] text-white p-1 rounded-lg text-[12px] sm:text-[14px] font-bold leading-none'>
                          {`${item.discount}% OFF`}
                        </button>
                      )}
                    </div>
                    <div className='flex gap-5'>
                      {item?.selections?.map((selection, index) => (
                        <p key={index} className='text-[#6D6D6D] text-sm'>
                          {`${selection.size} x ${selection.quantity}`}
                        </p>
                      ))}
                    </div>
                    <p className='text-[#6D6D6D] mt-1 font-[600] text-sm'>
                      MRP/kg: {item.discount ? (
                        <>
                          <del>₹{item.price.toFixed(2)}</del> <span className='text-gray-800'>₹{calculateDiscountedPrice(item.price, item.discount).toFixed(2)}</span>
                        </>
                      ) : `₹${item.price.toFixed(2)}`}
                    </p>
                  </div>
                   <div className='flex-shrink-0 ml-auto'>
                  <p className='w-[18px] h-[18px] hover:scale-110 text-[#FF0000] font-[500] cursor-pointer' onClick={() => removeFromCart(item._id)}>X</p>
                </div>
                </div>
               
              </div>
            ))
          ) : (
            <>
              <div className="flex justify-center items-center h-[200px]">
                <img src={CartEmpty} alt="Wheat" className="h-[200px] w-[150px]" />
              </div>
              <div className="flex justify-center items-center text-center text-gray-600 font-bold text-[20px]">
                No products found
              </div>
            </>
          )}
        </div>

        <div className="p-5">
          <p className="text-[16px] font-dm-sans">
            <span className="font-medium text-black">Subtotal:</span> 
            <span className="text-[#6D6D6D] font-medium"> ₹{subtotal.toFixed(2)}</span>
          </p>
          <div className='flex flex-col gap-5 mt-5 sm:flex-row'>
            <button className='w-full sm:w-[133px] h-[54px] border rounded-full border-black text-black hover:bg-gray-100' onClick={NavigateToCartDetails}>
              VIEW CART
            </button>
            <button
              className={`w-full sm:w-[133px] h-[54px] rounded-[27px] text-white ${cart.length === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#FF9F00]'}`}
              onClick={NavigateToAddDeliveryAddress}
              disabled={cart.length === 0}
            >
              CHECKOUT
            </button>
          </div>
        </div>
        <LoginRegisterModel isModalOpen={isModelOpen} />
      </div>
    </div>
  );
};

export default DrawerModal;
