import React, { useEffect, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import user from "../../asset/user.png";
import { BaseUrl } from "../../ContextApi/Url/BaseUrl";

const YourComponent = ({ productDescription, productCode }) => {
  const [activeTab, setActiveTab] = useState("description");
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(`${BaseUrl}/reviews/product/${productCode}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setReviews(data.reviewList);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productCode]); // Re-fetch when productCode changes

  return (
    <>
      <div className="flex items-center mb-4">
        <p
          onClick={() => handleTabClick("description")}
          className={`cursor-pointer font-bold ${activeTab === "description" ? "text-[#C6A26F] underline" : "text-[#4C3F35]"}`}
        >
          PRODUCT DESCRIPTION
        </p>

        <div className="w-px h-6 bg-[#C6A26F] mx-2" />

        <p
          onClick={() => handleTabClick("reviews")}
          className={`cursor-pointer font-bold ${activeTab === "reviews" ? "text-[#C6A26F] underline" : "text-[#4C3F35]"}`}
        >
          CUSTOMER REVIEWS
        </p>
      </div>

      <div>
        {activeTab === "description" && <p>{productDescription}</p>}

        {activeTab === "reviews" && (
          <div className="mt-4">
            {loading && <p>Loading reviews...</p>}
            {error && <p>Error fetching reviews: {error}</p>}
            {!loading && !error && reviews.length === 0 && (
              <p className="text-lg text-center text-gray-600">No reviews for this product.</p>
            )}
            {!loading && !error && reviews.length > 0 && (
              <div className="flex flex-wrap gap-4">
                {reviews.map((review, index) => (
                  <div key={review.id} className="w-full max-w-md mx-auto overflow-hidden border rounded-lg shadow-lg sm:w-1/2">
                    <div className="flex items-center p-4">
                      <img src={user} alt={review.name} className="w-16 h-16 mr-4 rounded-full" />
                      <div className="flex-grow">
                        <div className="flex items-center justify-between mb-2">
                          <h3 className="text-lg font-semibold">{review.name}</h3>
                          <div className="flex items-center">
                            {[...Array(review.rating)].map((_, index) => (
                              <svg
                                key={index}
                                className="w-5 h-5 text-yellow-300"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                            ))}
                            {[...Array(5 - review.rating)].map((_, index) => (
                              <svg
                                key={index}
                                className="w-5 h-5 text-gray-300"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                            ))}
                          </div>
                        </div>
                        <p className="text-gray-500">
                          <FaCalendar className="inline mr-1" />
                          {new Date(review.createdAt).toLocaleDateString("en-US", {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })}
                        </p>
                        <p className="mt-2 text-gray-600">{review.review}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default YourComponent;

// Use your component like this
// <YourComponent productDescription={productDetails.productDescription} productCode={productDetails._id} />
