import React from "react";
import Quailitybg from "../../asset/Quailitybg.png";
import Wheateimg from "../../asset/wheateImage.png";
import Quailityimg1 from "../../asset/Quailityimg1.png";
import Quailityimg2 from "../../asset/Quailityimg2.png";
import Quailityimg3 from "../../asset/Quailityimg3.png";
import Quailityimg4 from "../../asset/Quailityimg4.png";

function How_we_make_Quaility() {
  return (
    <div
      className="w-full h-auto py-10 bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${Quailitybg})` }}
    >
      {/* Heading Section */}
      <div className="flex items-center justify-center">
        <p
          className="flex items-center text-[18px] sm:text-[24px] text-[#4C3F35] mt-[60px] sm:mt-[100px]"
          style={{
            fontFamily: "Dancing Script, cursive",
            fontWeight: 700,
            lineHeight: "28.8px",
            textAlign: "left",
          }}
        >
          <img
            src={Wheateimg}
            alt="Wheat"
            className="w-[30px] sm:w-[41.43px] h-[25px] sm:h-[30px] mr-2"
          />
          How We Make Quality
        </p>
      </div>

      <div className="flex items-center justify-center mt-2">
        <p
          className="text-left text-[32px] sm:text-[42px]"
          style={{
            fontFamily: "Lexend",
            fontWeight: 700,
            lineHeight: "52.5px",
          }}
        >
          How We Work It?
        </p>
      </div>

      {/* Steps Section */}
      <div className="grid grid-cols-1 gap-10 px-5 mt-5 sm:grid-cols-2 lg:grid-cols-4 sm:px-20">
        {/* Step 1 */}
        <div>
          <img
            src={Quailityimg1}
            alt="Wheat"
            className="w-[253px] h-[253px] object-cover rounded-lg ml-10 md:ml-0"
          />
          <p
            className="text-center text-[#6D6D6D] mt-2"
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18.23px",
            }}
          >
            STEP - 01
          </p>
          <p
            className="text-center"
            style={{
              fontFamily: "Lexend",
              fontSize: "22px",
              fontWeight: 400,
              lineHeight: "50px",
            }}
          >
            Work Planning
          </p>
          <p
            className="text-center text-[#6D6D6D]"
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18.23px",
            }}
          >
            Begin by conducting thorough soil <br /> tests to understand its composition, <br /> pH levels, and nutrients.
          </p>
        </div>

        {/* Step 2 */}
        <div className="mt-10 lg:mt-28">
          <img
            src={Quailityimg2}
            alt="Wheat"
            className="w-[253px] h-[253px] object-cover rounded-lg ml-10 md:ml-0"
          />
          <p
            className="text-center text-[#6D6D6D] mt-2"
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18.23px",
            }}
          >
            STEP - 02
          </p>
          <p
            className="text-center"
            style={{
              fontFamily: "Lexend",
              fontSize: "22px",
              fontWeight: 400,
              lineHeight: "50px",
            }}
          >
            Soil Preparation
          </p>
          <p
            className="text-center text-[#6D6D6D]"
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18.23px",
            }}
          >
            Properly plow and fertilize the soil <br /> for optimal growth and yield.
          </p>
        </div>

        {/* Step 3 */}
        <div>
          <img
            src={Quailityimg3}
            alt="Wheat"
            className="w-[253px] h-[253px] object-cover rounded-lg ml-10 md:ml-0"
          />
          <p
            className="text-center text-[#6D6D6D] mt-2"
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18.23px",
            }}
          >
            STEP - 03
          </p>
          <p
            className="text-center"
            style={{
              fontFamily: "Lexend",
              fontSize: "22px",
              fontWeight: 400,
              lineHeight: "50px",
            }}
          >
            Seed Selection
          </p>
          <p
            className="text-center text-[#6D6D6D]"
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18.23px",
            }}
          >
            Select high-quality seeds to <br /> ensure healthy and robust crops.
          </p>
        </div>

        {/* Step 4 */}
        <div className="mt-10 lg:mt-28">
          <img
            src={Quailityimg4}
            alt="Wheat"
            className="w-[253px] h-[253px] object-cover rounded-lg ml-10 md:ml-0"
          />
          <p
            className="text-center text-[#6D6D6D] mt-2"
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18.23px",
            }}
          >
            STEP - 04
          </p>
          <p
            className="text-center"
            style={{
              fontFamily: "Lexend",
              fontSize: "22px",
              fontWeight: 400,
              lineHeight: "50px",
            }}
          >
            Harvesting
          </p>
          <p
            className="text-center text-[#6D6D6D]"
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "18.23px",
            }}
          >
            Harvest the crops at the right <br /> time to maximize yield and quality.
          </p>
        </div>
      </div>
    </div>
  );
}

export default How_we_make_Quaility;
