import React from 'react';
import Quality1 from '../../asset/Quality1.png';
import Quality2 from '../../asset/Quality2.png';
import Quality3 from '../../asset/Quality3.png';
import Quality4 from '../../asset/Quality4.png';
import Wheateimg from '../../asset/wheateImage.png';
import Whychoosebg from '../../asset/Whychoosebg.png';
import whyChooseIMg from '../../asset/whyChooseIMg.png';

function Why_Choose_Us() {
  return (
    <div className='flex flex-col justify-between mt-10 lg:flex-row' style={{ 
      backgroundImage: `url(${Whychoosebg})`, 
      backgroundRepeat: 'no-repeat', 
      backgroundPosition: 'center', 
      backgroundSize: 'cover', 
      minHeight: '400px' // Adjusted for better minimum height on smaller devices
    }}>
      <div className='flex-1 pl-5 lg:pl-[300px] pt-10 lg:pt-[100px]'>
        <div>
          <p className="flex items-center text-[20px] lg:text-[24px] text-[#4C3F35]" style={{ fontFamily: 'Dancing Script, cursive', fontWeight: 700, lineHeight: '28.8px', textAlign: 'left' }}>
            <img src={Wheateimg} alt="Wheat" className="w-[30px] h-[30px] mr-2" />
            Why Choose Us
          </p>
          <p style={{ fontFamily: 'Lexend, sans-serif', fontSize: '24px' }}>
            Nourish Your Body With<br />
            Pure Organic Goodness!
          </p>
          <p className='mt-5 text-sm lg:text-base'>
            Governments have regulations in place to ensure that products labeled as<br />
            organic meet specific standards. Regular inspections and audits are conducted<br />
            to maintain the integrity of the organic label.
          </p>
        </div>

        <div className='flex flex-wrap gap-5 mt-14'>
          <div className='w-full sm:w-[45%] lg:w-[277px] h-[113px] bg-white flex justify-center items-center'>
            <img src={Quality1} alt="Quality 1" className="max-h-full" />
          </div>
          <div className='w-full sm:w-[45%] lg:w-[277px] h-[113px] bg-white flex justify-center items-center'>
            <img src={Quality2} alt="Quality 2" className="max-h-full" />
          </div>
          <div className='w-full sm:w-[45%] lg:w-[277px] h-[113px] bg-white flex justify-center items-center'>
            <img src={Quality3} alt="Quality 3" className="max-h-full" />
          </div>
          <div className='w-full sm:w-[45%] lg:w-[277px] h-[113px] bg-white flex justify-center items-center'>
            <img src={Quality4} alt="Quality 4" className="max-h-full" />
          </div>
        </div>
      </div>
      <div className='hidden lg:block'>
        <img src={whyChooseIMg} alt="Why Choose Us" className="w-[593px] h-[642px]" />
      </div>
    </div>
  );
}

export default Why_Choose_Us;
