import React, { useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { loginDealer, registerDealer } from '../../ContextApi/Service/Auth';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';
function LoginRegisterModel({ isModalOpen, onClose }) {
  const [view, setView] = useState('login'); // 'login', 'register', 'forgetPassword', 'otp', 'resetPassword'
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [Emailerror, setEmailError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [otp, setOtp] = useState(Array(6).fill('')); // Store OTP as an array of 6 elements
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState(''); // State for email
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
   const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPasswordNew, setShowConfirmPasswordNew] = useState(false);
     
  const [dealerData, setDealerData] = useState({
        ShopAddress:"",
        shopName: '',
        contactPerson: '',
        email: '',
        gstNumber: '',
        password: '',
        CommissionDoneAmount:'',
        confirmPassword: '',  
        FSSAINumber: '',
        contactNumber:''
  });
  const [errors, setErrors] = useState({
    shopName: '',
    contactPerson: '',
    email: '',
    gstNumber: '',
    password: '',
    ShopAddress:'',
    confirmPassword: '',
    FSSAINumber: '',
    contactNumber:''
  });
   const [countdown, setCountdown] = useState(60); // Countdown state
    const [errorMessage, setErrorMessage] = useState('');
 const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
   useEffect(() => {
        // Start countdown when view is 'otp'
        if (view === 'otp') {
            const interval = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(interval);
                      setView('forgetPassword'); // Switch to login view when countdown expires
                      setSuccess(null)
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000); // Update every second

            return () => clearInterval(interval); // Clear interval on unmount
        }
    }, [view]);
 const handleEmailChange = (e) => {
        setEmail(e.target.value);
   setError(null); // Clear error when user types in the input
   setEmailError(null)
    };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
 const handleChange = (e) => {
    const { name, value } = e.target;

    // GST number validation (at least 15 characters, restrict to exactly 15 characters)
    if (name === 'gstNumber') {
        const gstPattern = /^[0-9A-Za-z]*$/;

        if (!gstPattern.test(value)) {
            setErrorMessage('GST number should only contain alphanumeric characters.');
            return;
        } else if (value.length > 15) {
            return;
        } else {
            setErrorMessage('');
        }
    }

    // Contact number validation (exactly 10 digits)
    if (name === 'contactNumber') {
        if (!/^\d*$/.test(value) || value.length > 10) {
            setErrorMessage('Contact number should only contain digits and be 10 digits long.');
            return;
        } else {
            setErrorMessage('');
        }
    }

    // FSSAI number validation (exactly 14 digits, only numbers allowed)
    if (name === 'FSSAINumber') {
        if (!/^\d*$/.test(value)) {
            // setErrorMessage('FSSAI number should only contain digits.');
            return;
        } else if (value.length > 14) {
            return; // Stop input if it exceeds 14 digits
        }
        // else if (value.length < 14 && value.length > 0) {
        //     setErrorMessage('FSSAI number must be exactly 14 digits long.');
        // }
        else {
            setErrorMessage(''); // Clear error message if valid
        }
    }

    // Update state
    setDealerData((prevData) => ({ ...prevData, [name]: value }));
};



 useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'unset'; // Enable scrolling
    }

    // Clean up on unmount
    return () => {
      document.body.style.overflow = 'unset'; // Ensure scrolling is enabled when component unmounts
    };
  }, [isModalOpen]);
    if (!isModalOpen) return null;
    
 const handleLogin = async (e) => {
    e.preventDefault();

    try {
        // Call the login API
        const data = await loginDealer(email, password);

        if (data.result) {
            // Store the token in localStorage
            localStorage.setItem('authtoken', data.token);
            localStorage.setItem('dealerInfo', JSON.stringify(data.DealerInformation)); // Store dealer info as a JSON string
            setLoggedIn(true); // Set loggedIn to true

            // Show success modal
            Swal.fire({
                icon: 'success',
                title: 'Login Successful',
                text: data.message,
                confirmButtonText: 'OK'
            }).then(() => {
                onClose(); // Close modal
                window.location.reload(); // Reload the page
            });
        } else {
            // Show error modal
            Swal.fire({
                icon: 'error',
                title: 'Login Failed',
                text: data.message,
                confirmButtonText: 'Retry'
            });
        }
    } catch (error) {
        // Show generic error modal
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while logging in. Please try again later.',
            confirmButtonText: 'OK'
        });
    }
};
    
  const handleRegister = async (e) => {
  debugger
    e.preventDefault();

      const newErrors = {
        ShopAddress:'',
        shopName: '',
        contactPerson: '',
        email: '',
        gstNumber: '',
        password: '',
        confirmPassword: '',
        FSSAINumber: '',
        contactNumber: ''
    };

    // Validate fields
    let hasErrors = false;

    if (!dealerData.shopName) {
        newErrors.shopName = 'Shop Name is required';
        hasErrors = true;
      }
      if (!dealerData.ShopAddress) {
        newErrors.ShopAddress = 'Shop Address is required';
        hasErrors = true;
  }
  if (!dealerData.contactNumber) {
        newErrors.contactNumber = 'Contact Number Name is required';
        hasErrors = true;
  }
  if (dealerData.contactNumber.length !==  10) {
        newErrors.contactNumber = 'Contact number must be exactly 10 digits';
        hasErrors = true;
    }
    if (!dealerData.contactPerson) {
        newErrors.contactPerson = 'Contact Person is required';
        hasErrors = true;
    }
    if (!dealerData.email) {
        newErrors.email = 'Email is required';
        hasErrors = true;
    }
    if (!dealerData.gstNumber) {
        newErrors.gstNumber = 'GST Number is required';
        hasErrors = true;
  }
   if (dealerData.gstNumber.length !==  15) {
    newErrors.gstNumber = 'GST Number must be exactly 15 characters long';
    hasErrors = true;
}
    if (!dealerData.password) {
        newErrors.password = 'Password is required';
        hasErrors = true;
    }
    if (!dealerData.confirmPassword) {
        newErrors.confirmPassword = 'Passwords must match';
        hasErrors = true;
    }
    if (!dealerData.FSSAINumber) {
        newErrors.FSSAINumber = 'FSSAI Number is required';
        hasErrors = true;
    }

 if (dealerData.FSSAINumber.length !== 14) {
    newErrors.FSSAINumber = 'FSSAI number must be exactly 14 digits long.';
    hasErrors = true;
}

    setErrors(newErrors);

    if (hasErrors) return;

   try {
    const data = await registerDealer(dealerData);
    if (data.result) {
        // Show SweetAlert success message
        Swal.fire({
            title: 'Success!',
            text: 'Dealer registered successfully!',
            icon: 'success',
            confirmButtonText: 'OK'
        }).then(() => {
            // Reload the screen after the user clicks 'OK'
            window.location.reload();
        });
    } else {
        setErrorMessage(data.message || 'Registration failed.');
    }
} catch (error) {
    setErrorMessage('An error occurred while registering. Please try again.');
}
};


  const HandleCloseModel = () => {
    onClose()
    setView('login')
    setError(null)
    setSuccess(null)
    setMessage('')
    setEmail('')
      setPassword('')
      setConfirmPassword('')
      setErrorMessage('')
      setErrors({})
    setOtp('')
    setCountdown(60)
    setDealerData({
       shopName: '',
        contactPerson: '',
        email: '',
        gstNumber: '',
        password: '',
        CommissionDoneAmount:'',
        confirmPassword: '',  
        FSSAINumber: '',
        contactNumber:''
    })
    }
    const changeTab = () => {
        
    }

  const handleSendOTP = async () => {
    setMessage('')
    setOtp('')
    if (email === '') {
        setEmailError('Please enter your email.');
        return; // Exit the function if the email is empty
    }

   
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
        setEmailError('Please enter a valid email address.');
        return; 
    }
        setLoading(true);
        setError(null);
        setSuccess(null);

      try {
    setLoading(true); // Start loading before the request
    const response = await fetch(`${BaseUrl}/Dealer/forgot-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
    });

    // Check if the response is OK (status in the range 200-299)
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Server error. Please try again.');
    }

    const data = await response.json();
    setSuccess(data.message);
        setView('otp'); // Switch to OTP view if needed
        setCountdown(60)
} catch (err) {
    setError(err.message);
} finally {
    setLoading(false);
}

  };
  
   const handleChangeSetView = (index, value) => {
        // Update the OTP array
        const newOtp = [...otp];
        newOtp[index] = value;

        // Update state
        setOtp(newOtp);

        // Move to next input if a digit is entered
        if (/\d/.test(value) && index < 5) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleVerifyOtp = async () => {
        const otpString = otp.join(''); // Convert the array to a string
        try {
            const response = await fetch(`${BaseUrl}/Dealer/verify-otp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, otp: otpString }),
            });

            const data = await response.json();
            if (response.ok) {
                // OTP verified successfully
                setView('resetPassword'); // Navigate to reset password view
            } else {
                // Handle error
                setMessage(data.message);
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            setMessage('Server error. Please try again later.');
        }
  };
  const handleResetPassword = async () => {
        if (!newPassword || !confirmPassword) {
    setErrorMessage('Password and Confirm Password is Required.');
    return;
}

if (newPassword !== confirmPassword) {
    setErrorMessage('Passwords do not match.');
    return;
}

         try {
        const response = await fetch(`${BaseUrl}/Dealer/reset-password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, newPassword }),
        });

        const data = await response.json();
        if (response.ok) {
            // Show success alert
            Swal.fire({
                icon: 'success',
                title: 'Password Reset',
                text: 'Password reset successfully. You can now log in.',
            });

            // Reset all fields and view
            setMessage('');
            setView('login'); // Redirect to login view after successful reset
            setOtp('');
            setPassword('');
            setNewPassword('');
            setSuccess(null);
            setConfirmPassword('');
            setErrors({});
        } else {
            // Show error alert if there's an issue with the request
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: data.message || 'Failed to reset password. Please try again.',
            });
        }
    } catch (error) {
        console.error('Error resetting password:', error);

        // Show server error alert
        Swal.fire({
            icon: 'error',
            title: 'Server Error',
            text: 'Server error. Please try again later.',
        });
    }
    };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative p-8 px-4 bg-white rounded-lg shadow-lg">
      {view === 'login' && (
  <div className='max-w-md p-4 mx-auto bg-white rounded-lg md:w-[568px] md:h-[402px] '>
    <p className="mb-4 text-2xl font-medium text-center">Welcome!</p>
    <form onSubmit={handleLogin} className="mt-5">
      <div>
        <label className="text-lg font-medium">Email<span className="text-red-500">*</span></label>
        <input
          type="email"
          className="p-2 pl-4 bg-gray-100 border rounded-full w-full h-[50px] mt-2"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="relative mt-5">
        <label className="text-lg font-medium">Password<span className="text-red-500">*</span></label>
        <input
          type={showPassword ? 'text' : 'password'}
          className="p-2 pl-4 bg-gray-100 border rounded-full w-full h-[50px] mt-2"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <span
          className="absolute mt-4 transform -translate-y-1/2 cursor-pointer right-3 top-1/2"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <AiFillEye size={24} /> : <AiFillEyeInvisible size={24} />}
        </span>
      </div>
      <p className='mt-2 font-medium cursor-pointer text-end hover:underline' onClick={() => setView('forgetPassword')}>Forget Your Password?</p>
      <div className='flex justify-center mt-5'>
        <button type="submit" className='w-full h-[43px] bg-[#4C3F35] text-white rounded-full'>Login</button>
      </div>
      <div className='flex justify-center gap-1 mt-5'>
        <span className='text-[#6D6D6D]'>Don’t have an account?</span>
        <span className='text-[#4C3F35] underline cursor-pointer' onClick={() => setView('register')}>Register here</span>
      </div>
    </form>
  </div>
)}


     {view === 'register' && (
    <div className='max-w-[1100px] w-full mx-auto h-auto px-4 overflow-y-auto scrollbar-hidden' style={{ maxHeight: '80vh' }}>
        <p className="mb-4 text-[20px] text-center font-[500]">Registration</p>
        <form onSubmit={handleRegister}>
            <div className='flex flex-col justify-center gap-4 mt-10 md:flex-row'>
                <div className="relative flex-1 mb-4">
                    <div className='text-[16px] font-[500]'>Shop Name</div>
                    <input
                        type='text'
                        name='shopName'
                        value={dealerData.shopName}
                        onChange={handleChange}
                        placeholder='Your Shop Name'
                        className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] mt-2"
                    />
                    {errors.shopName && <p className="text-sm text-red-500">{errors.shopName}</p>}
                </div>
                <div className="relative flex-1 mb-4">
                    <div className='text-[16px] font-[500]'>Contact Person</div>
                    <input
                        type='text'
                        name='contactPerson'
                        value={dealerData.contactPerson}
                        onChange={handleChange}
                        placeholder='Contact Person'
                        className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] mt-2"
                    />
                    {errors.contactPerson && <p className="text-sm text-red-500">{errors.contactPerson}</p>}
                </div>
            </div>
            <div className='flex flex-col justify-center gap-4 mt-2 md:flex-row'>
                <div className="relative flex-1 mb-4">
                    <div className='text-[16px] font-[500]'>Email</div>
                    <input
                        type='email'
                        name='email'
                        value={dealerData.email}
                        onChange={handleChange}
                        placeholder='Email Address'
                        className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
                    />
                    {errors.email && <p className="text-sm text-red-500">{errors.email}</p>}
                </div>
                <div className="relative flex-1 mb-4">
                    <div className='text-[16px] font-[500]'>GST Number</div>
                    <input
                        type='text'
                        name='gstNumber'
                        value={dealerData.gstNumber}
                        onChange={handleChange}
                        placeholder='GST Number'
                        className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
                    />
                    {errors.gstNumber && <p className="text-sm text-red-500">{errors.gstNumber}</p>}
                </div>
            </div>
            <div className='flex flex-col justify-center gap-4 mt-2 md:flex-row'>
                <div className="relative flex-1 mb-4">
                    <div className='text-[16px] font-[500]'>Create Password</div>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        value={dealerData.password}
                        onChange={handleChange}
                        placeholder='Create Password'
                        className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
                    />
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute mt-5 right-3 top-3"
                    >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                    {errors.password && <p className="text-sm text-red-500">{errors.password}</p>}
                </div>

                <div className="relative flex-1 mb-4">
                    <div className='text-[16px] font-[500]'>Confirm Password</div>
                    <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        name='confirmPassword'
                        value={dealerData.confirmPassword}
                        onChange={handleChange}
                        placeholder='Confirm Password'
                        className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
                    />
                    <button
                        type="button"
                        onClick={toggleConfirmPasswordVisibility}
                        className="absolute mt-5 right-3 top-3"
                    >
                        {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                    {errors.confirmPassword && <p className="text-sm text-red-500">{errors.confirmPassword}</p>}
                </div>
            </div>
            <div className='flex flex-col justify-center gap-4 mt-2 md:flex-row'>
                <div className="relative flex-1 mb-4">
                    <div className='text-[16px] font-[500]'>Contact Number</div>
                    <input
                        type='text'
                        name='contactNumber'
                        value={dealerData.contactNumber}
                        onChange={handleChange}
                        placeholder='Contact Number'
                        maxLength={10}
                        className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
                    />
                    {errors.contactNumber && <p className="text-sm text-red-500">{errors.contactNumber}</p>}
                </div>
                <div className="relative flex-1 mb-4">
                    <div className='text-[16px] font-[500]'>FSSAI Number</div>
                    <input
                        type='text'
                        name='FSSAINumber'
                        value={dealerData.FSSAINumber}
                        onChange={handleChange}
                        placeholder='FSSAI Number'
                        className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
                    />
                    {errors.FSSAINumber && <p className="text-sm text-red-500">{errors.FSSAINumber}</p>}
                </div>
            </div>
            <div className='flex justify-center gap-4 mt-2'>
                <div className="relative flex-1 mb-4">
                    <div className='text-[16px] font-[500]'>Shop Address</div>
                    <input
                        type='text'
                        name='ShopAddress'
                        value={dealerData.ShopAddress}
                        onChange={handleChange}
                        placeholder='Your Shop Address'
                        className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] mt-2"
                    />
                    {errors.ShopAddress && <p className="text-sm text-red-500">{errors.ShopAddress}</p>}
                </div>
            </div>
            {errorMessage && <p className="text-center text-red-500 font-[600]">{errorMessage}</p>}
            <div className='flex justify-center mt-2'>
                <button type='submit' className='w-[214px] h-[43px] bg-[#4C3F35] text-white rounded-[30px]'>SAVE</button>
            </div>
            <div className='flex justify-center gap-1 mt-5'>
                <span className='text-[#6D6D6D]'>Already have an account?</span>
                <span className='text-[#4C3F35] underline cursor-pointer' onClick={() => setView('login')}>Login here</span>
            </div>
        </form>
    </div>
)}




        {
    view === 'forgetPassword' && (
        <div className='h-auto max-w-md p-4 mx-auto md:w-[568px] md:h-[402px]'>
            <p className="mb-4 text-[20px] text-center font-[500]">Forgot Password</p>

            {/* Display success message */}
            {success && <p className="text-center text-green-500">{success}</p>}
            
            {/* Display error message */}
            {error && <p className="p-2 text-red-500">{error}</p>}

            <div className="mt-10">
                <label className="text-[16px] font-[500]">
                    Email<span className="text-red-500">*</span>
                </label>
                <div className="mt-2">
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange} // Use the new handler
                        className="p-2 bg-gray-100 border rounded-full w-full h-[50px] pl-5"
                        placeholder="Enter your email address"
                    />
                </div>
                {Emailerror && <p className='p-2 text-red-500'>{Emailerror}</p>}
            </div>

            <div className='flex justify-center mt-20'>
                <button
                    className='w-full max-w-xs h-[43px] bg-[#4C3F35] text-white rounded-[30px]'
                    onClick={handleSendOTP}
                    disabled={loading}
                >
                    {loading ? 'Sending...' : 'Send OTP'}
                </button>
            </div>

            <div className='flex justify-center gap-1 mt-5'>
                <span className='text-[#6D6D6D]'>Remember your password?</span>
                <span className='text-[#4C3F35] underline cursor-pointer' onClick={() => setView('login')}>Login here</span>
            </div>
        </div>
    )
}

{view === 'otp' && (
    <div className='max-w-md p-4 mx-auto md:w-[568px] md:h-[402px]'>
        <p className="mb-4 text-lg font-medium text-center">Enter OTP sent to {email}</p>
        <div className="flex justify-center gap-2 mt-10">
            {Array.from({ length: 6 }).map((_, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="w-12 h-12 p-2 text-center bg-gray-100 border rounded-full focus:outline-none focus:ring focus:ring-blue-300"
                    placeholder="0"
                    autoComplete="off"
                    value={otp[index]} // Controlled input
                    onChange={(e) => handleChangeSetView(index, e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace' && index > 0 && !otp[index]) {
                            document.getElementById(`otp-input-${index - 1}`).focus();
                        }
                    }}
                    onFocus={(e) => e.target.select()} // Select the input text on focus
                    id={`otp-input-${index}`}
                />
            ))}
        </div>
        {message && <p className='mt-2 text-center text-red-500'>{message}</p>} {/* Error message */}
        <p className='mt-2 text-center text-red-500'>Time left: {countdown}s</p> {/* Countdown display */}
        <div className='flex justify-center mt-5 md:mt-[100px]'>
            <button
                className='w-full max-w-xs h-10 bg-[#4C3F35] text-white rounded-full'
                onClick={handleVerifyOtp} // Call the API on button click
            >
                Verify OTP
            </button>
        </div>
        <div className='mt-4 text-center'>
            <p className='text-sm font-medium'>
                By continuing, you agree to Aakar’s<br />
                <span className='text-[#C6A26F]'>Terms & Conditions</span> and <span className='text-[#C6A26F]'>Privacy Policy</span>
            </p>
        </div>
    </div>
)}




    {view === 'resetPassword' && (
    <div className='w-full max-w-[568px] h-auto p-4 mx-auto'>
        <p className="mb-4 text-lg font-medium text-center">Reset Password</p>

        {/* New Password Section */}
        <div className="p-2 mt-5">
            <div>
                <label className="text-base font-medium">New Password
                    <span className="text-red-500">*</span>
                </label>
            </div>
            <div className="relative mt-2">
                <input
                    type={showNewPassword ? 'text' : 'password'} // Toggle between text and password
                    className="p-2 bg-gray-100 border rounded-full w-full h-[50px]"
                    placeholder="Enter new password"
                    value={newPassword} // Controlled input
                    onChange={(e) => setNewPassword(e.target.value)} // Handle input change
                    onCopy={(e) => e.preventDefault()} // Disable copy
                    onPaste={(e) => e.preventDefault()} // Disable paste
                />
                <span
                    className="absolute transform -translate-y-1/2 cursor-pointer right-4 top-1/2"
                    onClick={() => setShowNewPassword(!showNewPassword)} // Toggle password visibility
                >
                    {showNewPassword ?  <FiEye size={20} /> : <FiEyeOff size={20} />} {/* Eye icon from react-icons */}
                </span>
            </div>
        </div>

        {/* Confirm Password Section */}
        <div className="p-2 mt-2">
            <div>
                <label className="text-base font-medium">Confirm Password
                    <span className="text-red-500">*</span>
                </label>
            </div>
            <div className="relative mt-2">
                <input
                    type={showConfirmPasswordNew ? 'text' : 'password'} // Toggle between text and password
                    className="p-2 bg-gray-100 border rounded-full w-full h-[50px] md:w-[300px] md:h-[50px]"
                    placeholder="Confirm new password"
                    value={confirmPassword} // Controlled input
                    onChange={(e) => setConfirmPassword(e.target.value)} // Handle input change
                    onCopy={(e) => e.preventDefault()} // Disable copy
                    onPaste={(e) => e.preventDefault()} // Disable paste
                />
                <span
                    className="absolute transform -translate-y-1/2 cursor-pointer right-4 top-1/2"
                    onClick={() => setShowConfirmPasswordNew(!showConfirmPasswordNew)} // Toggle password visibility
                >
                    {showConfirmPasswordNew ? <FiEye size={20} /> :  <FiEyeOff size={20} />} {/* Eye icon from react-icons */}
                </span>
            </div>
        </div>

        {/* Error Message */}
        {errorMessage && <p className="font-semibold text-center text-red-500">{errorMessage}</p>}

        {/* Reset Button */}
        <div className='flex justify-center mt-10'>
            <button
                className='w-full max-w-[214px] h-[43px] bg-[#4C3F35] text-white rounded-[30px] md:w-[100px] md:h-[43px] md:w-[214px] md:h-[43px]'
                onClick={handleResetPassword} // Call the reset password function
            >
                Reset Password
            </button>
        </div>
    </div>
)}


        <button
          onClick={HandleCloseModel}
          className="absolute top-0 right-5 p-2 text-gray-600 hover:text-gray-800 text-[30px]"
        >
          ×
        </button>
      </div>
    </div>
  );
}

export default LoginRegisterModel;
