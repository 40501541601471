// import React, { createContext, useContext, useEffect, useState } from 'react';

// // Create the Wishlist context
// const WishlistContext = createContext();

// // WishlistProvider component to manage wishlist state
// export const WishlistProvider = ({ children }) => {
//   const [wishlist, setWishlist] = useState([]);

//   // Load wishlist from localStorage on mount
//   useEffect(() => {
//     const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
//     setWishlist(storedWishlist);
//   }, []);

//   // Function to add an item to the wishlist
//   const addToWishlist = (item) => {
//     setWishlist((prevWishlist) => {
//       // Check for duplicates
//       if (prevWishlist.some(existingItem => existingItem.id === item.id)) {
//         return prevWishlist; // Item already exists, do not add
//       }

//       const updatedWishlist = [...prevWishlist, item];
//       localStorage.setItem('wishlist', JSON.stringify(updatedWishlist)); // Update localStorage
//       return updatedWishlist;
//     });
//   };

//   // Function to remove an item from the wishlist
//   const removeFromWishlist = (itemId) => {
//   console.log('Removing item with ID:', itemId); // Debugging line

//   // Find the index of the item with the specified ID
//   const productIndex = wishlist.findIndex(item => item.id === itemId);

//   // If the item exists, remove it
//   if (productIndex !== -1) {
//     const updatedWishlist = [...wishlist]; // Create a shallow copy of the wishlist
//     updatedWishlist.splice(productIndex, 1); // Remove the item at the found index

//     // Update localStorage with the new wishlist
//     localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));

//     // Update the state
//     setWishlist(updatedWishlist);
//   }
// };

//   // Return the context provider with state and functions
//   return (
//     <WishlistContext.Provider value={{ wishlist, addToWishlist, removeFromWishlist }}>
//       {children}
//     </WishlistContext.Provider>
//   );
// };

// // Custom hook to use the Wishlist context
// export const useWishlist = () => {
//   return useContext(WishlistContext);
// };

// // Export WishlistContext and WishlistProvider
// export { WishlistContext };
import React, { createContext, useContext, useEffect, useState } from 'react';

// Create the Wishlist context
const WishlistContext = createContext();

// WishlistProvider component to manage wishlist state
export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([]);

  // Load wishlist from localStorage on mount
  useEffect(() => {
    const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlist(storedWishlist);
  }, []);

  // Function to add an item to the wishlist
  const addToWishlist = (item) => {
    setWishlist((prevWishlist) => {
      if (prevWishlist.some(existingItem => existingItem._id === item._id)) {
        return prevWishlist; // Item already exists, do not add
      }
      const updatedWishlist = [...prevWishlist, item];
      localStorage.setItem('wishlist', JSON.stringify(updatedWishlist)); // Update localStorage
      return updatedWishlist;
    });
  };

  // Function to remove an item from the wishlist
  const removeFromWishlist = (itemId) => {
  // First, remove the item from the wishlist state
  setWishlist((prevWishlist) => {
    const updatedWishlist = prevWishlist.filter(item => item._id !== itemId); // Remove item from state
    
    // Update localStorage with the new wishlist after modifying the state
    localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
    
    return updatedWishlist; // Return the updated wishlist state
  });
};


  return (
    <WishlistContext.Provider value={{ wishlist, addToWishlist, removeFromWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};

// Custom hook to use the Wishlist context
export const useWishlist = () => {
  return useContext(WishlistContext);
};

// Export WishlistContext and WishlistProvider
export { WishlistContext };
