import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { BeatLoader } from 'react-spinners';// Add this for the loading spinner
import Swal from 'sweetalert2';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';
import animationDataPending from '../../asset/PendingReturn.json';
import animationDataRejected from '../../asset/RejectReturn.json';
import animationData from '../../asset/ReturnApprove.json';

function ReturnProductApply({ onClickCloseTheReturn, product, orderId, customerInfo, Duepayment, totalAmount,shopId }) {
  console.log(product, "product");
  console.log(orderId, "orderId");
  const [returnReason, setReturnReason] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [returnStatus, setReturnStatus] = useState(null); // State to hold return status
  const [rejectedMessage, setRejectedMessage] = useState(''); // State to hold rejected message
  const [loading, setLoading] = useState(false); // Loading state

  const handleInputChange = (e) => {
    setReturnReason(e.target.value);
    setErrorMessage(''); // Clear error message when the user types
  };

  const handleSubmit = async () => {
    if (!returnReason.trim()) {
      setErrorMessage('Please provide a reason for canceling.');
      return;
    }

    // Data to send to the API
    const data = {
      OrderId: orderId,
      ShopId:shopId,
      OrderDate: product.OrderDate,
      DuePayment: Duepayment,
      totalAmount: totalAmount,
      productDetails: {
        ProductID: product.ProductID,
        ProductName: product.ProductName,
        MRP: product.MRP,
        discount: product.discount || 0,
        selectedImages: product.selectedImages || [],
        selection: product.selection,
        returnStatus: "Pending",
        RejectedMessage: ""
      },
      SupplierInfo: product.SupplierInfo,
      customerInfo: customerInfo,
      returnMessage: returnReason,
    };

    setLoading(true); // Show loading spinner
    try {
      const response = await fetch(`${BaseUrl}/purchase-return/addReturnOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      setLoading(false); // Hide loading spinner

      if (response.ok) {
        console.log('Return order submitted successfully:', result);
        Swal.fire({
          title: 'Success!',
          text: 'Return order submitted successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.reload();
        });
        onClickCloseTheReturn();
      } else {
        Swal.fire({
          title: 'Error!',
          text: result.message || 'Failed to submit return order. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        setErrorMessage('Failed to submit return order. Please try again.');
      }
    } catch (error) {
      setLoading(false); // Hide loading spinner
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while submitting the return order.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      setErrorMessage('An error occurred while submitting the return order.');
    }
  };

  // UseEffect to fetch return status on component mount
  useEffect(() => {
    const fetchReturnStatus = async () => {
      setLoading(true); // Show loading spinner
      try {
        const response = await fetch(`${BaseUrl}/purchase-return/Get_the_ReturnStatus`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            OrderId: orderId,
            ProductId: product.ProductID, // Assuming ProductID is available
          }),
        });

        const result = await response.json();
        setLoading(false); // Hide loading spinner

        if (response.ok) {
          console.log('Return status fetched successfully:', result);
          setReturnStatus(result.returnStatus); // Set the return status from the response
          setRejectedMessage(result.rejectedMessage); // Set the rejected message if any
        } else {
          console.error('Failed to fetch return status:', result.message);
        }
      } catch (error) {
        setLoading(false); // Hide loading spinner
        console.error('Error fetching return status:', error);
      }
    };

    fetchReturnStatus();
  }, [orderId, product.ProductID]); // Dependencies to trigger the effect

  return (
   <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
  <div className="w-full max-w-lg p-5 bg-white rounded-lg shadow-lg">
    <div className='flex justify-between px-6 pt-3'>
      {returnStatus === "Pending" && (
        <h2 className="text-xl font-semibold md:text-2xl">Return Request Pending</h2>
      )}
      {returnStatus === "Rejected" && (
        <h2 className="text-xl font-semibold md:text-2xl">Return Request Rejected</h2>
      )}
      {returnStatus === "Refund" && (
        <h2 className="text-xl font-semibold md:text-2xl">Return Request Approved</h2>
      )}
      {returnStatus === null && (
        <h2 className="text-xl font-semibold md:text-2xl">Request For Return</h2>
      )}
      <MdCancel onClick={onClickCloseTheReturn} className="w-5 h-5 cursor-pointer" />
    </div>
    <hr className='mt-5' />

    {loading ? (
      <div className="flex justify-center mt-10">
        <BeatLoader color="#4C3F35" loading={loading} size={15} />
      </div>
    ) : returnStatus === "Refund" ? (
      <div className="flex flex-col items-center w-full">
        <Lottie
          animationData={animationData}
          loop={true}
          className="w-48 h-full"
        />
        <p className='p-2 text-lg font-medium text-center text-orange-400 md:text-xl'>Order Approved</p>
        <div className='text-[#139F30] text-base md:text-lg font-medium p-2 text-left'>
          <p>Your return request for {orderId} of {product.ProductName} has been approved!</p>
        </div>
      </div>
    ) : returnStatus === "Pending" ? (
      <div className="flex flex-col items-center w-full">
        <Lottie
          animationData={animationDataPending}
          loop={true}
          className="w-48 h-full"
        />
        <p className='p-2 text-lg font-medium text-center text-orange-400 md:text-xl'>Return Order Pending</p>
        <div className='p-2 text-base font-medium text-left md:text-lg'>
          <p>Your return request for {orderId} of {product.ProductName} has already been sent and is currently pending.</p>
        </div>
      </div>
    ) : returnStatus === "Rejected" ? (
      <div className="flex flex-col items-center w-full">
        <Lottie
          animationData={animationDataRejected}
          loop={true}
          className="w-48 h-full"
        />
        <p className='p-2 text-lg font-medium text-center text-orange-400 md:text-xl'>Order Rejected</p>
        <div className='p-2 text-base font-medium text-left md:text-lg'>
          Unfortunately, your return request for {product.ProductName} (Order ID: {orderId}) has been rejected due to the following reason:<br />
          {rejectedMessage}
        </div>
      </div>
    ) : (
      <>
        <div className='px-6 pt-3'>
          <p className='text-[#222222] text-lg md:text-xl mt-5'>Could you tell us a reason for canceling?</p>
          <input
            type="text"
            placeholder='Tell us'
            value={returnReason}
            onChange={handleInputChange}
            className="w-full mt-10 text-lg border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 md:text-xl"
          />
          {errorMessage && (
            <p className='text-red-500'>{errorMessage}</p>
          )}
          <div className="flex justify-center mt-6">
            <button
              className="bg-[#FF3F3F] text-white font-semibold text-lg py-2 px-6 rounded-full"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </>
    )}
  </div>
</div>

  );
}

export default ReturnProductApply;
