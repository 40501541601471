import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './App.css';
// Import the ScrollToTop component
import ScrollToTop from "./Componenet/ScroolTop";
import About from './Pages/About/About';
import CartPage from "./Pages/CartPage/CartPage";
import Contact_Page from './Pages/Contact/Contact_Page';
import Footer from './Pages/Footer/Footer';
import Header from './Pages/Header/Header';
import HomePage from './Pages/Home/HomePage';
import AddingAddress from "./Pages/OrderPalceFlow/AddingAddress";
import PaymentMethod from "./Pages/OrderPalceFlow/PaymentMethod";
import Product_Details from './Pages/ProductDetails/Product_Details';
import ShopPage from './Pages/Shop/ShopPage';
import MyOrderPage from "./Pages/UserPages/MyOrderPage";
import OrderDetails from "./Pages/UserPages/OrderDetailsPage";
import ProfilePage from "./Pages/UserPages/ProfilePage";
import WishListPage from "./Pages/WishList/WishListPage";
import TermsAndCondition from "./Pages/policy/TermsAndCondition";
import PrivacyPolicy from "./Pages/policy/PrivacyPolicy";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/About" element={<About />} />
        <Route path="/Shop" element={<ShopPage />} />
        <Route path="/Contact" element={<Contact_Page />} />
        <Route path="/ShopDetails" element={<Product_Details />} />
        <Route path="/CartDetails" element={<CartPage />} />
        <Route path="/Wishlist" element={<WishListPage />} />
        <Route path="/AddDeliveryAddress" element={<AddingAddress />} />
        <Route path="/PaymentMethod" element={<PaymentMethod />} />
        <Route path="/MyOrders" element={<MyOrderPage />} />
        <Route path="/ViewOrderDetails" element={<OrderDetails />} />
        <Route path="/MyProfile" element={<ProfilePage />} />
        <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
