import React, { useState } from 'react'
import AboutBg from '../../asset/AboutBg.png';
import { IoLocationSharp } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaUser, FaPhone, FaEnvelope } from 'react-icons/fa';
import { CiUser } from "react-icons/ci";
import { FaEdit } from "react-icons/fa";
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';
import Swal from 'sweetalert2';
function Contact_Page() {
    const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Handle input changes
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Validate form inputs
  const validate = () => {
    let tempErrors = {};

    // Name validation
    if (!formData.name) tempErrors.name = 'Name is required';

    // Email validation
    if (!formData.email) tempErrors.email = 'Email is required';
    else {
      const emailPattern = /^\S+@\S+\.\S+$/;
      if (!emailPattern.test(formData.email)) tempErrors.email = 'Invalid email format';
    }

    // Phone validation
    if (!formData.phone) tempErrors.phone = 'Phone number is required';
    else if (!/^\d{10,15}$/.test(formData.phone)) tempErrors.phone = 'Invalid phone number';

    // Subject validation
    if (!formData.subject) tempErrors.subject = 'Subject is required';

    // Message validation
    if (!formData.message) tempErrors.message = 'Message is required';

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

  try {
  const response = await fetch(`${BaseUrl}/SubmitContactDetails`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });

  if (response.ok) {
    // Display SweetAlert for success
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: 'Your message has been sent successfully!',
      timer: 3000, // Auto-close after 3 seconds
      showConfirmButton: false,
    });

    // Clear form
    setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
  } else {
    // Handle any error responses
    const errorData = await response.json();

    // Display SweetAlert for error
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: errorData.error || 'Something went wrong. Please try again later.',
    });
  }
} catch (error) {
  // Catch any network or other errors
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Something went wrong. Please try again later.',
  });
}

  };
  return (
    <div>
     <div
      className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4 "
      style={{ backgroundImage: `url(${AboutBg})` }}
    >
      <h1
        className="text-[#222222]  "
        style={{
          fontFamily: 'Lexend',
          fontSize: '50px',
          fontWeight: 500,
          lineHeight: '40px',
          textAlign: 'left',
              }}

      >
     Contact Us
      </h1>
      <p className="mt-4 text-lg ">Home &gt;<span className='text-[#BE740A]'> Contact Us</span></p>
          </div>
          <div className='mt-10'>
             <p className='text-[42px] text-center' style={{
            fontFamily: "Lexend",
           
            fontWeight: 700,
            lineHeight: "50px",
              }}>Contact Information</p>
              <p className='mt-5 text-[16px] text-center text-[#6D6D6D]' style={{ fontFamily: 'DM Sans' }}>Organic foods are typically certified by regulatory bodies to ensure they meet<br></br> specific organic standards.</p>
          </div>
          
          <div className='flex flex-wrap justify-center gap-5 mt-10'>
  <div className='w-full sm:w-[274px] h-[204px] shadow-xl rounded-lg flex justify-center items-start gap-5 pt-5'>
    <div className='w-[66px] h-[64.48px] bg-[#4C3F35] rounded-lg flex justify-center items-center text-white'>
      <IoLocationSharp className='w-[44px] h-[44px]' />
    </div>
    <div>
      <p className='text-[20px]'>Our Address</p>
      <p>Office No.3, 5th Floor, <br /> Siddhi Pooja Business<br /> Square, Sharanpur<br /> Road,<br /> Nashik - 422005</p>
    </div>
  </div>

  <div className='w-full sm:w-[274px] h-[204px] shadow-xl rounded-lg flex justify-center items-start gap-5 pt-5'>
    <div className='w-[66px] h-[64.48px] bg-[#C6A26F] rounded-lg flex justify-center items-center text-white'>
      <IoCall className='w-[44px] h-[44px]' />
    </div>
    <div>
      <p className='text-[20px]'>Phone Number</p>
      <p>(+91) 99214 55999<br /> (+91) 81498 41266</p>
    </div>
  </div>

  <div className='w-full sm:w-[274px] h-[204px] shadow-xl rounded-lg flex justify-center items-start gap-2 pt-5'>
    <div className='w-[66px] h-[64.48px] bg-[#C6A26F] rounded-lg flex justify-center items-center text-white'>
      <MdEmail className='w-[44px] h-[44px]' />
    </div>
    <div>
      <p className='text-[20px]'>Email Address</p>
      <p className='text-[14px]'>anandtalera111@gmail.com</p>
    </div>
  </div>

  <div className='w-full sm:w-[274px] h-[204px] shadow-xl rounded-lg flex justify-center items-start gap-2 pt-5'>
    <div>
      <p className='text-[20px]'>Follow Social Media</p>
      <div className='flex gap-2 mt-5'>
        <div className='w-[45px] h-[45px] bg-[#5E4634] flex justify-center items-center rounded-lg text-white hover:scale-110'>
          <FaFacebookF className='w-[17px] h-[14px]' />
        </div>
        <div className='w-[45px] h-[45px] bg-[#5E4634] flex justify-center items-center rounded-lg text-white hover:scale-110'>
          <FaTwitter className='w-[17px] h-[14px]' />
        </div>
        <div className='w-[45px] h-[45px] bg-[#5E4634] flex justify-center items-center rounded-lg text-white hover:scale-110'>
          <FaInstagram className='w-[17px] h-[14px]' />
        </div>
        <div className='w-[45px] h-[45px] bg-[#5E4634] flex justify-center items-center rounded-lg text-white hover:scale-110'>
          <FaYoutube className='w-[17px] h-[14px]' />
        </div>
      </div>
    </div>
  </div>
</div>


         <div className="flex justify-center px-4 mt-10 mb-10">
  <div className="w-full h-auto max-w-4xl p-10 pb-5 rounded-lg shadow-2xl">
    <p className="font-lexend text-3xl md:text-[42px] font-bold leading-[50px] text-center mt-5">
      Get In Touch
    </p>
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col justify-center gap-4 mt-10 md:flex-row md:gap-6">
        <div className="w-full md:w-1/2">
          <div className="relative">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Your Name"
              className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <CiUser className="text-[#6D6D6D] w-[25px] h-[20px]" />
            </div>
          </div>
          {errors.name && <p className="p-2 text-xs text-red-500">{errors.name}</p>}
        </div>

        <div className="w-full md:w-1/2">
          <div className="relative">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email Address"
              className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <MdEmail className="text-[#6D6D6D] w-[25px] h-[20px]" />
            </div>
          </div>
          {errors.email && <p className="p-2 text-xs text-red-500">{errors.email}</p>}
        </div>
      </div>

      <div className="flex flex-col justify-center gap-4 mt-5 md:flex-row md:gap-6">
        <div className="w-full md:w-1/2">
          <div className="relative">
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Phone Number"
              max={10}
              className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <IoCall className="text-[#6D6D6D] w-[25px] h-[20px]" />
            </div>
          </div>
          {errors.phone && <p className="p-2 text-xs text-red-500">{errors.phone}</p>}
        </div>

        <div className="w-full md:w-1/2">
          <div className="relative">
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              placeholder="Subject"
              className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
            />
          </div>
          {errors.subject && <p className="p-2 text-xs text-red-500">{errors.subject}</p>}
        </div>
      </div>

      <div className="flex mt-5 md:px-0">
        <div className="relative w-full">
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder="Your Message"
            className="block md:w-full h-[142px] py-3 pl-4 pr-12 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 bg-[#F7F7F7] w-[250px]"
            rows="4"
          ></textarea>
          {errors.message && <p className="p-2 text-xs text-red-500">{errors.message}</p>}
        </div>
      </div>

      <div className="flex justify-center mt-5">
        <button
          type="submit"
          className="px-6 py-3 text-white rounded-full bg-[#735944] hover:bg-brown-700 w-full md:w-[1046px]"
        >
          SEND MESSAGE &gt;&gt;
        </button>
      </div>

      {successMessage && <p className="mt-5 text-center text-green-500">{successMessage}</p>}
      {errorMessage && <p className="mt-5 text-center text-red-500">{errorMessage}</p>}
    </form>
  </div>
</div>


       <div className="flex justify-center mt-10 mb-20">
  <div className="overflow-hidden rounded-lg" style={{ width: '1320px', height: '584px' }}>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d29993.39314750988!2d73.72705705!3d20.001204599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1722488708093!5m2!1sen!2sin"
      width="1320"
      height="584"
      style={{ border: '0' }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      className="rounded-lg"
    ></iframe>
  </div>
</div>

    </div>
  )
}

export default Contact_Page
