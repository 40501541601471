import React, { useEffect, useState } from 'react';
import AboutBg from '../../asset/AboutBg.png';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';
function TermsAndCondition() {
  const [terms, setTerms] = useState('');

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await fetch(`${BaseUrl}/GetTermsAndCondition`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // Assuming the first object contains the content you want to display
        setTerms(data[0]?.content || '');
      } catch (error) {
        console.error('Failed to fetch terms and conditions:', error);
      }
    };

    fetchTerms();
  }, []);

  return (
    <>
    
    <div
      className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4 "
      style={{ backgroundImage: `url(${AboutBg})` }}
    >
      <h1
        className="text-[#222222] md:text-[50px] text-[30px] "
        style={{
          fontFamily: 'Lexend',
          // fontSize: '50px',
          fontWeight: 500,
          lineHeight: '40px',
          textAlign: 'left',
              }}

      >
       Terms And Conditions
      </h1>
      <p className="mt-4 text-lg ">Home &gt;<span className='text-[#BE740A]'> Terms And Conditions</span></p>
      </div>
      
      {/* <div className='flex justify-center mt-10 mb-5'>

 <div className="text-center terms-and-conditions" dangerouslySetInnerHTML={{ __html: terms }} />
      </div> */}
      <div className="flex flex-col items-center justify-center w-full p-4 ">
        
        <div className='mt-5'>
          <div>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
  1. Definitions
            </p>
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
  We may collect the following types of information:
            </p>
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
1.1 "Company" refers to Aakar Canvassing.<br></br>
1.2 "Factory" refers to the producers or suppliers of bhagar and grains.<br></br>
1.3 "Dealer" refers to the buyers or traders purchasing bhagar and grains from Aakar Canvassing.<br></br>
1.4 "Commission" refers to the agreed percentage or fee payable to Aakar Canvassing for facilitating <br></br>transactions.
          </p>
          </div>
            
          <hr className="mt-5 border-t-2 border-black" />
          


          <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
2. Scope of Services
            </p>
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
  We may collect the following types of information:
            </p>
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
2.1 Aakar Canvassing acts as an intermediary between factories and dealers to facilitate the sale and <br></br>purchase of bhagar and
     grains.
2.2 The Company earns a commission from:<br></br>
Factories, for arranging the sale of their products to dealers.<br></br>
Dealers, for facilitating the purchase of products from factories.
          </p>
          </div>
            
          <hr className="mt-5 border-t-2 border-black" />
          


          <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
3. Payment Terms
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
3.1 Commission from Factories: Factories agree to pay Aakar Canvassing the agreed commission percentage<br></br> upon
     successful sale of their products.<br></br>
3.2 Commission from Dealers: Dealers agree to pay Aakar Canvassing the agreed commission percentage upon<br></br> successful
      purchase of products.<br></br>
3.3 Payments must be made within 8 days of the transaction date, unless otherwise agreed in writing.<br></br>
3.4 Late payments may incur an additional charge of 10% per month.<br></br>
          </p>
          </div>
            
            <hr className="mt-5 border-t-2 border-black" />


           <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
4. Obligations of Factories and Dealers
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
4.1 Factories must ensure the quality and quantity of goods supplied as per the agreed terms with the <br></br>dealer.<br></br>
4.2 Dealers must inspect and confirm receipt of goods before making payments.<br></br>
4.3 Both parties must provide accurate and complete information regarding their requirements, products,<br></br> and payments.
          </p>
          </div>
            
          <hr className="mt-5 border-t-2 border-black" />
          

          <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
5. Dispute Resolution
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
5.1 Aakar Canvassing is not responsible for disputes arising between factories and dealers regarding <br></br>quality, quantity, or<br></br>
     payment.<br></br>
5.2 All disputes must be resolved between the respective parties. Aakar Canvassing may assist in <br></br>mediation upon request
      but holds no liability.
          </p>
          </div>
            
          <hr className="mt-5 border-t-2 border-black" />
          


           <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
6. Confidentiality
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
6.1 Both factories and dealers agree to keep all transaction details confidential and not disclose them<br></br> to any third party
     without prior consent.
          </p>
          </div>
            
          <hr className="mt-5 border-t-2 border-black" />
          
           <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
7. Termination of Services
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
7.1 Aakar Canvassing reserves the right to terminate services for non-compliance with these Terms or any <br></br>unethical practices.<br></br>
7.2 Either party may terminate their relationship with Aakar Canvassing by providing 30 days' written <br></br>notice.
          </p>
          </div>
            
          <hr className="mt-5 border-t-2 border-black" />
          

           <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
8. Limitation of Liability
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
8.1 Aakar Canvassing shall not be held liable for any loss or damages arising from:<br></br>
Disputes between factories and dealers.<br></br>
Delays, defects, or deficiencies in products.<br></br>
Non-payment or defaults by either party.<br></br>
8.2 The Company's total liability shall be limited to the commission earned from the specific <br></br>transaction in question.
          </p>
          </div>
            
          <hr className="mt-5 border-t-2 border-black" />
          


          <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
9. Amendments
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
9.1 Aakar Canvassing reserves the right to amend these Terms at any time. Updated terms will be <br></br>communicated to all parties
     involved.
          </p>
          </div>
            
          <hr className="mt-5 border-t-2 border-black" />
          


            <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
10. Governing Law
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
10.1 These Terms shall be governed by and construed in accordance with the laws of India.
          </p>
          </div>
            
            <hr className="mt-5 border-t-2 border-black" />

          
          <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
10. Contact Us
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
If you have questions or concerns about this Privacy Policy or your information, please contact us at:<br></br>
Email: anandtalera111@gmail.com<br></br>
Phone: (+91) 99214 55999 |  (+91) 81498 41266<br></br>
Address: : Office No.3, 5th Floor, Siddhi Pooja Business Square, Sharanpur Road, Nashik - 422005
          </p>
          </div>
            
          <hr className="mt-5 border-t-2 border-black" />
          
           <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
By engaging with Aakar Canvassing, you confirm that you have read, understood, and agree to these Terms<br></br> and Conditions.
          </p>
        </div>
       
      </div>
      
    </>
   
  );
}

export default TermsAndCondition;
