import React, { useEffect, useState } from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import AboutImageChnage from "../../asset/AboutImageChnage.png";
import AboutourCOmpany2 from "../../asset/AboutourCOmpany2.png";
import AboutourCOmpany3 from "../../asset/AboutourCOmpany3.png";
import imgabout1 from "../../asset/imgabout1.png";
import imgabout2 from "../../asset/imgabout2.png";
import offerimg1 from "../../asset/offerimg1.png";
import offerimg2 from "../../asset/offerimg2.png";
import Wheateimg from "../../asset/wheateImage.png";
import BestDeal from "../../Componenet/HomePage_Component.jsx/BestDeal";
import Count from "../../Componenet/HomePage_Component.jsx/Count";
import OrganicProduct from "../../Componenet/HomePage_Component.jsx/OrganicProduct";
import Testimonial from "../../Componenet/HomePage_Component.jsx/Testimonial";
import Why_Choose_Us from "../../Componenet/HomePage_Component.jsx/Why_Choose_Us";
import Offer_Cards from "../../Componenet/Offer_Cards";
import { BaseUrl } from "../../ContextApi/Url/BaseUrl";

function HomePage() {

   const [data, setdata] = useState([]);
console.log(data)
  const fetchBannerData = async () => {
    try {
      const response = await fetch(`${BaseUrl}/HomeBanner/GetBanner`);
      const result = await response.json();
      setdata(result[0]); // Assuming you want the first banner
    } catch (error) {
      console.error('Error fetching banner data:', error);
    }
  };

  useEffect(() => {
    fetchBannerData();
  }, []);
  return (
    <>
      <div
        className="md:h-[700px] w-full bg-cover bg-center bg-no-repeat sm:flex sm:justify-center md:flex-none md:justify-start"
       style={{ backgroundImage: `url(${data?.image})` }}
      >
        <div className="p-4 sm:p-10 sm:pl-28 sm:pt-52">
          <p
            className="flex items-center text-[20px] sm:text-[24px] text-[#4C3F35] sm:text-center"
            style={{
              fontFamily: "Dancing Script, cursive",
              fontWeight: 700,
              lineHeight: "28.8px",
              textAlign: "left",
            }}
          >
            <img
              src={Wheateimg}
              alt="Wheat"
              className="w-[30px] sm:w-[41.43px] h-[20px] sm:h-[30px] mr-2 "
            />
            100% Organic Foods
          </p>

          <p className="text-[40px] sm:text-[80px] font-lexend font-bold leading-[48px] sm:leading-[110px] text-left mt-4 sm:mt-0">
            {/* <div className="flex">
              <p>Organic Fruits</p>
            </div>
            For Health */}
          <div className="flex md:w-[700px] break-words">
  {data?.text}
</div>


          </p>

          <div className="mt-10">
            <Link to='/Shop' className="flex bg-[#735944] text-white py-2 px-4 h-[54px] w-full sm:w-[100px] md:w-[187px] justify-center gap-2 rounded-full items-center md:hover:scale-110 transition-transform duration-300 sm:hover:scale-110">
              DISCOVER MORE{" "}
              <MdKeyboardDoubleArrowRight className="w-[25.48px] h-[24.48px]" />
            </Link>
          </div>
        </div>
      </div>

     <div className="hidden mt-10">
  <div className="flex justify-center">
    <p
      className="flex items-center text-[18px] md:text-[24px] text-[#4C3F35]"
      style={{
        fontFamily: "Dancing Script, cursive",
        fontWeight: 700,
        lineHeight: "1.2",
        textAlign: "left",
      }}
    >
      <img
        src={Wheateimg}
        alt="Wheat"
        className="w-[30px] h-[20px] md:w-[41.43px] md:h-[30px] mr-2"
      />
      Food Category
    </p>
  </div>
  <div>
    <p
      className="flex justify-center mt-5 text-[24px] md:text-[42px]"
      style={{
        fontFamily: "Lexend, sans-serif",
        fontWeight: 700,
        lineHeight: "1.25",
        textAlign: "left",
      }}
    >
      What We’re Offering
    </p>
  </div>
  <div>
    <Offer_Cards />
  </div>
</div>


      {/* <div className="flex justify-center gap-10 p-10 ">
        <div className="flex mt-32">
          <img
            src={AboutourCOmpany2}
            alt="Wheat"
            className="w-[325px] h-[348.67px] mr-2 "
          />
          <div>
            <img
              src={AboutourCOmpany1}
              alt="Wheat"
              className="w-[325px] h-[348.67px] mr-2 mt-[-150px]"
            />
            <img
              src={AboutourCOmpany3}
              alt="Wheat"
              className="w-[222px] h-[178px] mr-2"
            />
          </div>
        </div>
        <div>
          <p
            className="flex items-center text-[24px] text-[#4C3F35]"
            style={{
              fontFamily: "Dancing Script, cursive",
              fontWeight: 700,
              lineHeight: "28.8px",
              textAlign: "left",
            }}
          >
            <img
              src={Wheateimg}
              alt="Wheat"
              className="w-[41.43px] h-[30px] mr-2"
            />
            About Our Company
          </p>
          <p
            style={{
              fontFamily: "Lexend",
              fontSize: "42px",
              fontWeight: 700,
              lineHeight: "50px",
              textAlign: "left",
            }}
            className="mt-5"
          >
            Eating Right Start With <br></br>Organic Food
          </p>
          <p className="mt-5">
            Organic foods are produced through a farming system that avoids the
            <br></br>
            use of synthetic pesticides, herbicides, genetically modified
            organism<br></br>
            (GMOs), and artificial fertilizers. Instead, organic farmers rely on
            natural<br></br>
            methods like crop rotation. composting, and biological pest control.
          </p>
          <div className="flex justify-between mt-10">
            <div>
              <img
                src={imgabout1}
                alt="Wheat"
                className="w-[236px] h-[59px] mr-2 rounded-lg"
              />
            </div>
            <div>
              <img
                src={imgabout2}
                alt="Wheat"
                className="w-[236px] h-[59px] mr-2 rounded-lg"
              />
            </div>
          </div>
          <hr className="mt-10"></hr>
          <div className="mt-5">
            <Link to='/Shop' className="flex bg-[#735944] text-white p-2 h-[54px] w-[187px] justify-center gap-2 rounded-full items-center hover:scale-110">
              DISCOVER MORE{" "}
              <MdKeyboardDoubleArrowRight className="w-[25.48px] h-[24.48px]" />
            </Link>
          </div>
        </div>
      </div> */}
  <div className="flex flex-col justify-center gap-10 p-10 md:pl-[200px] md:flex-row">
  <div className="flex items-center justify-center md:mt-32">
    <img
      src={AboutImageChnage}
      alt="Wheat"
      className="  md:w-[500px] md:h-[500px] mr-2"
    />
  </div>
  <div className="flex-1">
    <p
      className="flex items-center text-[24px] text-[#4C3F35]"
      style={{
        fontFamily: "Dancing Script, cursive",
        fontWeight: 700,
        lineHeight: "28.8px",
        textAlign: "left",
      }}
    >
      <img
        src={Wheateimg}
        alt="Wheat"
        className="w-[41.43px] h-[30px] mr-2"
      />
      About Our Company
    </p>
    <p
      className="mt-5"
      style={{
        fontFamily: "Lexend",
        fontSize: "42px",
        fontWeight: 700,
        lineHeight: "50px",
        textAlign: "left",
      }}
    >
      Eating Right Starts With <br /> Organic Food
    </p>
    <p className="mt-5 text-[16px] md:text-[18px]">
      Organic foods are produced through a farming system that avoids the
      <br />
      use of synthetic pesticides, herbicides, genetically modified
      organisms (GMOs), and artificial fertilizers. Instead, organic farmers
      rely on natural methods like crop rotation, composting, and biological
      pest control.
    </p>
    <div className="flex flex-wrap gap-2 mt-10 ">
      <div>
        <img
          src={imgabout1}
          alt="Wheat"
          className="w-full md:w-[236px] h-auto md:h-[59px] rounded-lg"
        />
      </div>
      <div className="mt-5 md:block md:mt-0">
        <img
          src={imgabout2}
          alt="Wheat"
          className="w-full md:w-[236px] h-auto md:h-[59px] rounded-lg"
        />
      </div>
    </div>
    <hr className="mt-10" />
    <div className="mt-5">
      <Link
        to="/Shop"
        className="flex bg-[#735944] text-white p-2 h-[54px] w-full md:w-[187px] justify-center gap-2 rounded-full items-center hover:scale-110 transition-transform duration-300"
      >
        DISCOVER MORE{" "}
        <MdKeyboardDoubleArrowRight className="w-[25.48px] h-[24.48px]" />
      </Link>
    </div>
  </div>
</div>





      {/* <div className="flex justify-center gap-10 p-10 mt-10">
        <div className="bg-[#F4D29A] w-[568px] h-[328px] rounded-[30px] flex justify-between p-4 items-center">
          <div>
            <p className="text-[20px] font-bold leading-[26.04px] text-left">
              Get Extra <span className="text-[#017D03]">50% Off</span>
            </p>
            <p className="text-[30px] font-bold leading-[42px] text-left mt-2">
              Fresh Vegetables &<br />
              Organic Fruits
            </p>
            <Link to='/Shop' className="flex bg-[#735944] text-white p-2 h-[55px] w-[168px] justify-center gap-2 rounded-full items-center mt-7">
              SHOP NOW{" "}
              <MdKeyboardDoubleArrowRight className="w-[25.48px] h-[24.48px]" />
            </Link>
          </div>
          <div>
            <img
              src={offerimg1}
              alt="Wheat"
              className="w-[213px] h-[213px] mr-2"
            />
          </div>
        </div>

        <div className="bg-[#FFCE7E] w-[568px] h-[328px] rounded-[30px] flex justify-between p-4 items-center">
          <div>
            <p className="text-[20px] font-bold leading-[26.04px] text-left">
              Get Extra <span className="text-[#017D03]">50% Off</span>
            </p>
            <p className="text-[30px] font-bold leading-[42px] text-left mt-2">
              Green & Organic<br></br>
              Vegetables
            </p>
            <Link to='/Shop' className="flex bg-[#735944] text-white p-2 h-[55px] w-[168px] justify-center gap-2 rounded-full items-center mt-7">
              SHOP NOW{" "}
              <MdKeyboardDoubleArrowRight className="w-[25.48px] h-[24.48px]" />
            </Link>
          </div>
          <div>
            <img
              src={offerimg2}
              alt="Wheat"
              className="w-[213px] h-[213px] mr-2"
            />
          </div>
        </div>
      </div> */}
      <div className="flex flex-col justify-center gap-10 p-5 mt-10 md:flex-row md:p-10">
  {/* First Card */}
  <div className="bg-[#F4D29A] w-full md:w-[568px] h-[328px] rounded-[30px] flex justify-between p-4 items-center">
    <div className="flex flex-col justify-between">
      <p className="text-[18px] md:text-[20px] font-bold leading-[26.04px] text-left">
        Get Extra <span className="text-[#017D03]">50% Off</span>
      </p>
      <p className="text-[24px] md:text-[30px] font-bold leading-[42px] text-left mt-2">
        Fresh Vegetables &<br />
        Organic Fruits
      </p>
      <Link to='/Shop' className="flex bg-[#735944] text-white p-2 h-[55px] w-full md:w-[168px] justify-center gap-2 rounded-full items-center mt-7">
        SHOP NOW{" "}
        <MdKeyboardDoubleArrowRight className="w-[25.48px] h-[24.48px]" />
      </Link>
    </div>
    <div className="flex-shrink-0">
      <img
        src={offerimg1}
        alt="Fresh Vegetables"
        className="w-[150px] h-[150px] md:w-[213px] md:h-[213px] object-contain"
      />
    </div>
  </div>

  {/* Second Card */}
  <div className="bg-[#FFCE7E] w-full md:w-[568px] h-[328px] rounded-[30px] flex justify-between p-4 items-center">
    <div className="flex flex-col justify-between">
      <p className="text-[18px] md:text-[20px] font-bold leading-[26.04px] text-left">
        Get Extra <span className="text-[#017D03]">50% Off</span>
      </p>
      <p className="text-[24px] md:text-[30px] font-bold leading-[42px] text-left mt-2">
        Green & Organic<br />
        Vegetables
      </p>
      <Link to='/Shop' className="flex bg-[#735944] text-white p-2 h-[55px] w-full md:w-[168px] justify-center gap-2 rounded-full items-center mt-7">
        SHOP NOW{" "}
        <MdKeyboardDoubleArrowRight className="w-[25.48px] h-[24.48px]" />
      </Link>
    </div>
    <div className="flex-shrink-0">
      <img
        src={offerimg2}
        alt="Organic Vegetables"
        className="w-[150px] h-[150px] md:w-[213px] md:h-[213px] object-contain"
      />
    </div>
  </div>
</div>


      <OrganicProduct />
      <div className="mb-10">
        <Why_Choose_Us />
      </div>
      <div>
        <Count />
      </div>
      <div className="mb-10 ">
        <BestDeal />
      </div>
      <div>
        {/* <Testimonial /> */}
      </div>
    </>
  );
}

export default HomePage;
