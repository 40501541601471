import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CancelProduct from '../../Componenet/Models/CancelProduct';

const OrderDetails = () => {
  const [ismodelOpen, setIsModelOpen] = useState(false);
  const location = useLocation();
  const { product, orderId, customerInfo, Duepayment } = location.state || {};

  console.log(product, Duepayment, "product");

  const HandleClickOpenModel = () => {
    setIsModelOpen(true);
  };

  const HandleClickOffModel = () => {
    setIsModelOpen(false);
  };

  // Trackable statuses (excluding payment for now)
  const statuses = [
    { key: 'Place', label: 'Order Place' },
    { key: 'Shipped', label: 'Shipped' },
    { key: 'Out_for_Delivery', label: 'Out for Delivery' },
   
    { key: 'Delivered', label: 'Delivered' },
     { key: 'Payment', label: 'Payment Done' },
  ];

 const getStatusClass = (status) => {
  if (status.key === 'Payment') {
    // Show Payment as green if Duepayment is 0 and Delivered is true
    if (Duepayment === "0" && product.OrderTrackingDetails['Delivered']) {
      return 'bg-green-500';
    }
    return 'bg-gray-300';
  }
  return product.OrderTrackingDetails[status.key] ? 'bg-green-500' : 'bg-gray-300';
};

  return (
   <div className="container p-4 mx-auto">
  <div className="mb-4">
    <a href="/MyOrders" className="text-blue-500">&lt; Back</a>
  </div>
  <div className="mb-6 text-sm text-gray-500">
    <a href="/" className="text-black hover:underline">Home</a> &gt; 
    <a href="/MyOrders" className="text-black hover:underline"> My Order</a> &gt; 
    <span className="font-semibold">{orderId}</span>
  </div>
  
  {/* Main Container */}
  <div className="p-6 bg-white rounded-lg">
    {/* Delivery and Supplier Information */}
    <div className="grid w-full grid-cols-1 gap-4 p-5 mb-6 border rounded-lg shadow-md md:grid-cols-2">
      <div>
        <h3 className="font-semibold">Delivery Address</h3>
        <p className="text-[16px] text-[#6D6D6D]">
          Mr. {customerInfo?.CustomerName}<br />
          Billing Address: {customerInfo?.Billing_Address}<br />
          Shipping Address: {customerInfo?.Shipping_Address}
        </p>
        <p><span className="text-[#6D6D6D]">Phone Number:</span> <span className="text-[#6D6D6D]">{customerInfo?.ContactNo}</span></p>
      </div>
      <div>
        <h3 className="font-semibold">Supplier Information</h3>
        <div className="text-[#6D6D6D]">
          <span>Factory Id: </span><span>{product?.SupplierInfo?.FactoryId}</span>
        </div>
        <div className="text-[#6D6D6D]">
          <span>Factory Name: </span><span>{product?.SupplierInfo?.FactoryName}</span>
        </div>
        <div className="text-[#6D6D6D]">
          <span>Factory Contact: </span><span>{product?.SupplierInfo?.FactoryContact}</span>
        </div>
        <div className="text-[#6D6D6D]">
          <span>Factory Address: </span><span>{product?.SupplierInfo?.FactoryAddress}</span>
        </div>
      </div>
    </div>

    {/* Product and Order Status */}
    <div className="flex flex-col gap-10 p-5 mb-4 border rounded-lg shadow-md lg:flex-row">
      {/* Product Info */}
      <div>
        <div className="bg-[#FFF9F0] w-full md:w-[272px] h-[25px] ml-5 mt-5 p-1 rounded-[30px] pl-5">
          <p className="text-[#6D6D6D] text-[12px]">Aakar Customer shared this order with you.</p>
        </div>
        <div className="flex items-start gap-5 mt-5">
          <img src={product?.selectedImages[0]} alt="Product" className="w-16 h-16" />
          <div>
            <h3 className="font-semibold">{product?.ProductName}</h3>
            <div className="text-[#6D6D6D]">
              {product.selection.map((selection, sIdx) => (
                <p key={sIdx}>BagSize: {selection.size}, QTY: {selection.quantity}</p>
              ))}
            </div>
            <p className="text-green-500">
              ₹{product.discount ? (product.MRP - (product.MRP * product.discount / 100)).toFixed(2) : product.MRP}/Kg
            </p>
          </div>
        </div>
      </div>

      {/* Order Status Tracking */}
     <div className="w-full mt-10 lg:mt-20">
  <div className="flex flex-col flex-wrap items-center justify-between lg:flex-row">
    {statuses.map(({ key, label }) => (
      <React.Fragment key={key}>
        <div className="flex-1 mb-4 text-center lg:mb-0">
          <div className={`flex items-center justify-center w-12 h-12 mx-auto rounded-full ${getStatusClass({ key })}`}>
            {(product.OrderTrackingDetails[key] || 
              (key === 'Payment' && Duepayment === "0" && product.OrderTrackingDetails['Delivered'])) && (
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            )}
          </div>
          <p className="mt-2 text-sm">{label}</p>
          {product.OrderTrackingDetails[key] && (
            <p className="text-xs text-gray-500">
              {product.OrderTrackingDetails[`${key}Note`] || 'No notes available'}
            </p>
          )}
        </div>
        {key !== 'Payment' && (
          <div className="hidden h-px bg-gray-300 lg:flex-1 lg:block"></div>
        )}
      </React.Fragment>
    ))}
  </div>
</div>

    </div>
  </div>
  
  <CancelProduct isModalOpen={ismodelOpen} onClose={HandleClickOffModel} />
</div>

  );
};

export default OrderDetails;
