import React from 'react';
import Vegimg from '../../src/asset/vegimg.png';

function Offer_Cards() {
  const cardData = [
    {
      id: 1,
      image: Vegimg,
      productCount: "Product (08)",
      productName: "Fresh Vegetable",
    },
    {
      id: 2,
      image: Vegimg,
      productCount: "Product (12)",
      productName: "Fresh Fruits",
    },
    {
      id: 3,
      image: Vegimg,
      productCount: "Product (05)",
      productName: "Dairy Products",
    },
    {
      id: 4,
      image: Vegimg,
      productCount: "Product (10)",
      productName: "Organic Grains",
    },
  ];

  return (
    <div className='flex flex-wrap items-center justify-center p-4 md:p-10'>
      {cardData.map((card) => (
        <div
          key={card.id}
          className='w-full sm:w-[200px] md:w-[274px] h-[260px] bg-[#FFF9F0] hover:bg-[#4C3F35] hover:text-white flex flex-col justify-center items-center rounded-xl m-2'
        >
          <img
            src={card.image}
            alt={card.productName}
            className="w-[80px] h-[80px] md:w-[100px] md:h-[100px] mb-2"
          />
          <p className='text-[14px]'>{card.productCount}</p>
          <p
            className='text-[16px] md:text-[18px]'
            style={{ fontFamily: 'DM Sans, sans-serif', fontWeight: 700, lineHeight: '23.44px', textAlign: 'left' }}
          >
            {card.productName}
          </p>
        </div>
      ))}
    </div>
  );
}

export default Offer_Cards;
