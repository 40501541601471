// import React from 'react';
// import { FaArrowRight } from "react-icons/fa6";
// import AakaarLogo from "../../asset/AakaarLogo.png";
// import FooterBg from '../../asset/FooterBg.png';
// import sepratorimgfooter from '../../asset/sepratorimgfooter.png';
// import Wheateimg from '../../asset/wheateImage.png';
// import { MdLocationPin } from "react-icons/md";
// import { IoCallSharp } from "react-icons/io5";
// import { IoMdMail } from "react-icons/io";
// import { FaFacebookF } from "react-icons/fa";
// import { FaWhatsapp } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa";
// function Footer() {
//     return (
//       <>
      
//        <div
//       style={{
//         backgroundImage: `url(${FooterBg})`,
//         backgroundSize: 'cover', // or 'contain' based on your preference
//         backgroundPosition: 'center',
//         backgroundRepeat: 'no-repeat',
//         height: '542px',
//         display: 'flex',
       
//         justifyContent: 'center',
       
//         color: '#fff',
//         textAlign: 'center',
        
//           }}
//           className='flex justify-center gap-20 pt-28 item center'
//     >
//           <div>
//               <div className='w-[274px] h-[102.13px] bg-white rounded-[25px] flex items-center'>
//                    <img src={AakaarLogo} alt="Aakaar Logo" className="h-[80px] w-[251.59px]" />
//               </div>
//               <div className='mt-10 text-white text-[14px]'>
//                   We provide specialized winterization<br></br>
// services to safeguard your pool during the<br></br>
// off-season, and when spring arrives, we<br></br>
// handle the thorough opening process.<br></br>
//               </div>
//           </div>
//           <div>
//               <div>
//                    <p className="flex items-center text-[24px] text-white font-bold">
//       <img src={Wheateimg} alt="Wheat" className="w-[41.43px] h-[30px] mr-2" />
//      Quick Links
//     </p>
//               </div>
//               <div className='mt-2'>
//                   <img src={sepratorimgfooter} alt="Wheat" className="w-[180px] h-[2px] rounded-[5px] ml-[20px]" />
//               </div>
//               <div className="mt-5">
//       <p className="font-dm-sans text-[20px] font-[500] leading-[26px] text-left flex gap-2 hover:text-gray-400">
//         <FaArrowRight className="mt-1.5 transition-transform duration-200 transform " />
//         Home
//       </p>
//       <p className="font-dm-sans text-[20px] font-[500] leading-[26px] text-left flex gap-2 mt-2 hover:text-gray-400 ">
//         <FaArrowRight className="mt-1.5 transition-transform duration-200 transform " />
//         About Us
//       </p>
//       <p className="font-dm-sans text-[20px] font-[500] leading-[26px] text-left flex gap-2 mt-2 hover:text-gray-400">
//         <FaArrowRight className="mt-1.5 transition-transform duration-200 transform " />
//         Shop
//       </p>
//       <p className="font-dm-sans text-[20px] font-[500] leading-[26px] text-left flex gap-2 mt-2 hover:text-gray-400 ">
//         <FaArrowRight className="mt-1.5 transition-transform duration-200 transform " />
//         Term & Condition
//       </p>
//       <p className="font-dm-sans text-[20px] font-[500] leading-[26px] text-left flex gap-2 mt-2 hover:text-gray-400">
//         <FaArrowRight className="mt-1.5 transition-transform duration-200 transform " />
//         Privacy & Policy
//       </p>
//       <p className="font-dm-sans text-[20px] font-[500] leading-[26px] text-left flex gap-2 mt-2 hover:text-gray-400">
//         <FaArrowRight className="mt-1.5 transition-transform duration-200 transform " />
//         Contact Us
//       </p>
//     </div>
//           </div>
//           <div>
//                             <div>
//                    <p className="flex items-center text-[24px] text-white font-bold">
//       <img src={Wheateimg} alt="Wheat" className="w-[41.43px] h-[30px] mr-2" />
//     Contact Us
//     </p>
//               </div>
//               <div className='mt-2'>
//                   <img src={sepratorimgfooter} alt="Wheat" className="w-[180px] h-[2px] rounded-[5px] ml-[20px]" />
//               </div>

//               <div className="flex gap-3 mt-5">
//       <div className="w-[29px] h-[29px] bg-[#5E4634] flex items-center justify-center rounded-[4px]">
//         <MdLocationPin className="text-[#C6A26F] w-[20.5px] h-[25px]" />
//       </div>
//       <div className="text-left">
//         <p>
//           Office No.3, 5th Floor, Siddhi<br />
//           Pooja Business Square,<br />
//           Sharanpur Road,<br />
//           Nashik - 422005
//         </p>
//       </div>
//               </div>
              
//                <div className="flex gap-3 mt-5">
//       <div className="w-[29px] h-[29px] bg-[#5E4634] flex items-center justify-center rounded-[4px]">
//         <IoCallSharp className="text-[#C6A26F] w-[20.5px] h-[25px]" />
//       </div>
//       <div className="text-left">
//         <p>
//          (+91) 99214 55999<br></br>
// (+91) 81498 41266
//         </p>
//       </div>
//               </div>
              
//                <div className="flex gap-3 mt-5">
//       <div className="w-[29px] h-[29px] bg-[#5E4634] flex items-center justify-center rounded-[4px]">
//         <IoMdMail  className="text-[#C6A26F] w-[20.5px] h-[25px]" />
//       </div>
//       <div className="text-left">
//         <p>
//         anandtalera111@gmail.com
//         </p>
//       </div>
//     </div>
//           </div>
//           <div>
//                <div>
//                    <p className="flex items-center text-[24px] text-white font-bold">
//       <img src={Wheateimg} alt="Wheat" className="w-[41.43px] h-[30px] mr-2" />
//     Social Media
//     </p>
//               </div> 
//               <div className='mt-2'>
//                   <img src={sepratorimgfooter} alt="Wheat" className="w-[180px] h-[2px] rounded-[5px] ml-[20px]" />
//               </div>
//               <div className='flex gap-10 mt-5'>
//                    <div className="w-[45px] h-[45px] bg-[#5E4634] flex items-center justify-center rounded-[4px]">
//         <FaFacebookF className="text-white w-[20.5px] h-[25px]" />
//                   </div>
//                    <div className="w-[45px] h-[45px] bg-[#5E4634] flex items-center justify-center rounded-[4px]">
//         <FaWhatsapp className="text-white w-[20.5px] h-[25px]" />
//                   </div>
//                    <div className="w-[45px] h-[45px] bg-[#5E4634] flex items-center justify-center rounded-[4px]">
//         <FaInstagram  className="text-white w-[20.5px] h-[25px]" />
//                   </div>
                  
//               </div>
              
//           </div>
//             </div>
//             <div className='bg-[#C6A26F] h-[66px]'>
//               <p className="font-medium leading-6 text-left text-white font-dm-sans text-16 pl-[200px] pt-5">
//   Copyright @ All Rights Reserved
// </p>

                
//             </div>
//       </>
   
//   );
// }

// export default Footer;
import React from 'react';
import { FaArrowRight, FaFacebookF, FaWhatsapp, FaInstagram } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { IoCallSharp } from "react-icons/io5";
import AakaarLogo from "../../asset/AakaarLogo.png";
import FooterBg from '../../asset/FooterBg.png';
import sepratorimgfooter from '../../asset/sepratorimgfooter.png';
import Wheateimg from '../../asset/wheateImage.png';
import { IoMdMail } from "react-icons/io";
import { Link } from 'react-router-dom';
function Footer() {
    return (
      <>
        <div
          style={{
            backgroundImage: `url(${FooterBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          className="flex flex-col justify-center gap-10 pt-10 pb-10 text-center text-white sm:items-center md:flex-row lg:gap-20 lg:pt-28 lg:text-left md:items-start"
        >
          <div>
            <div className="w-[274px] h-[102.13px] bg-white rounded-[25px] flex items-center justify-center mx-auto md:mx-0">
              <img src={AakaarLogo} alt="Aakaar Logo" className="h-[80px] w-[251.59px]" />
            </div>
            <div className="mt-5 md:mt-10 text-[14px]">
              We provide specialized winterization services to safeguard your<br></br> pool during the off-season, and when spring arrives,<br></br> we handle the thorough opening process.
            </div>
          </div>
          
          <div>
            <div>
              <p className="flex items-center justify-center md:justify-start text-[24px] font-bold">
                <img src={Wheateimg} alt="Wheat" className="w-[41.43px] h-[30px] mr-2" />
                Quick Links
              </p>
            </div>
            <div className="mt-2">
              <img src={sepratorimgfooter} alt="Separator" className="w-[180px] h-[2px] rounded-[5px] mx-auto md:mx-0" />
            </div>
           <div className="p-2 mt-5 md:p-0">
      <Link to="/" className="font-dm-sans text-[20px] font-[500] leading-[26px] flex gap-2 hover:text-gray-400 mt-2">
        <FaArrowRight className="mt-1.5 transition-transform duration-200 transform" />
        Home
      </Link>
      <Link to="/About" className="font-dm-sans text-[20px] font-[500] leading-[26px] flex gap-2 hover:text-gray-400 mt-2">
        <FaArrowRight className="mt-1.5 transition-transform duration-200 transform" />
        About Us
      </Link>
      <Link to="/Shop" className="font-dm-sans text-[20px] font-[500] leading-[26px] flex gap-2 hover:text-gray-400 mt-2">
        <FaArrowRight className="mt-1.5 transition-transform duration-200 transform" />
        Shop
      </Link>
      <Link to="/TermsAndCondition" className="font-dm-sans text-[20px] font-[500] leading-[26px] flex gap-2 hover:text-gray-400 mt-2">
        <FaArrowRight className="mt-1.5 transition-transform duration-200 transform" />
        Terms & Conditions
      </Link>
      <Link to="/PrivacyPolicy" className="font-dm-sans text-[20px] font-[500] leading-[26px] flex gap-2 hover:text-gray-400 mt-2">
        <FaArrowRight className="mt-1.5 transition-transform duration-200 transform" />
        Privacy & Policy
      </Link>
      <Link to="/Contact" className="font-dm-sans text-[20px] font-[500] leading-[26px] flex gap-2 hover:text-gray-400 mt-2">
        <FaArrowRight className="mt-1.5 transition-transform duration-200 transform" />
        Contact Us
      </Link>
    </div>
          </div>
          
          <div>
            <div>
              <p className="flex items-center justify-center md:justify-start text-[24px] font-bold">
                <img src={Wheateimg} alt="Wheat" className="w-[41.43px] h-[30px] mr-2" />
                Contact Us
              </p>
            </div>
            <div className="mt-2">
              <img src={sepratorimgfooter} alt="Separator" className="w-[180px] h-[2px] rounded-[5px] mx-auto md:mx-0" />
            </div>
            <div className="flex flex-col gap-5 p-2 mt-5 text-left md:p-0">
              <div className="flex gap-3">
                <div className="w-[29px] h-[29px] bg-[#5E4634] flex items-center justify-center rounded-[4px]">
                  <MdLocationPin className="text-[#C6A26F] w-[20.5px] h-[25px]" />
                </div>
                <p>
                 Office No.3, 5th Floor, Siddhi<br />
           Pooja Business Square,<br />
           Sharanpur Road,<br />
           Nashik - 422005
                </p>
              </div>
              <div className="flex gap-3">
                <div className="w-[29px] h-[29px] bg-[#5E4634] flex items-center justify-center rounded-[4px]">
                  <IoCallSharp className="text-[#C6A26F] w-[20.5px] h-[25px]" />
                </div>
                <p>
                  (+91) 99214 55999<br />
                  (+91) 81498 41266
                </p>
              </div>
              <div className="flex gap-3">
                <div className="w-[29px] h-[29px] bg-[#5E4634] flex items-center justify-center rounded-[4px]">
                  <IoMdMail className="text-[#C6A26F] w-[20.5px] h-[25px]" />
                </div>
                <p>anandtalera111@gmail.com</p>
              </div>
            </div>
          </div>
          
          <div>
            <div>
              <p className="flex items-center justify-center md:justify-start text-[24px] font-bold">
                <img src={Wheateimg} alt="Wheat" className="w-[41.43px] h-[30px] mr-2" />
                Social Media
              </p>
            </div>
            <div className="mt-2">
              <img src={sepratorimgfooter} alt="Separator" className="w-[180px] h-[2px] rounded-[5px] mx-auto md:mx-0" />
            </div>
            <div className="flex justify-center gap-5 mt-5 md:justify-start">
              {[FaFacebookF, FaWhatsapp, FaInstagram].map((Icon, index) => (
                <div key={index} className="w-[45px] h-[45px] bg-[#5E4634] flex items-center justify-center rounded-[4px]">
                  <Icon className="text-white w-[20.5px] h-[25px]" />
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div className="bg-[#C6A26F] h-[66px] flex items-center justify-center">
          <p className="font-medium leading-6 text-white font-dm-sans text-[16px]">
            Copyright @ All Rights Reserved
          </p>
        </div>
      </>
    );
}

export default Footer;
