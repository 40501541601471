import React, { useContext } from 'react';
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import AboutBg from '../../asset/AboutBg.png';
import { CartContext } from '../../ContextApi/AddtocartContext';
import CartEmpty from '../../asset/CartEmpty.png';
import Swal from 'sweetalert2';
function CartPage() {
  const navigate = useNavigate();
  const { cart, removeFromCart } = useContext(CartContext);
  console.log(cart)

//   const NavigateToAddDeliveryAddress = () => {
//   const token = localStorage.getItem('authtoken');
//   if (token) {
//     // Create an array of objects that contain both productId and selection for each product
//     const productsData = cart.map(item => ({
//       productId: item._id,         // Product ID
//       selection: item.selections   // Selection array (size and quantity)
//     }));

//     // Navigate to /AddDeliveryAddress and pass the productsData
//     navigate("/AddDeliveryAddress", { state: { productsData } });
//   } else {
//     alert("Please Login");
//   }
  // };
  const NavigateToAddDeliveryAddress = () => {
  const token = localStorage.getItem('authtoken');
  if (token) {
    // Create an array of objects that contain both productId and selection for each product
    const productsData = cart.map(item => ({
      productId: item._id,         // Product ID
      selection: item.selections   // Selection array (size and quantity)
    }));

    // Navigate to /AddDeliveryAddress and pass the productsData
    navigate("/AddDeliveryAddress", { state: { productsData } });
    // onClose();
  } else {
    Swal.fire({
      title: 'Please Login',
      text: 'You need to log in before proceeding.',
      icon: 'warning',
      confirmButtonText: 'OK'
    });
  }
};
const calculateTotal = () => {
  return cart.reduce((total, item) => {
    const { selections } = item;
    const discountedPrice = item.discount ? item.price * (1 - item.discount / 100) : item.price;

    // Calculate total for the current item based on selections
    const itemTotal = selections.reduce((itemTotal, selection) => {
      const sizeInKg = parseInt(selection.size); // Convert bag size to integer (e.g., 30kg to 30)
      return itemTotal + (discountedPrice * sizeInKg * selection.quantity);
    }, 0);

    return total + itemTotal;
  }, 0);
};


  return (
    <div>
      <div
        className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4"
        style={{ backgroundImage: `url(${AboutBg})` }}
      >
        <h1
          className="text-[#222222]"
          style={{
            fontFamily: 'Lexend',
            fontSize: '50px',
            fontWeight: 500,
            lineHeight: '40px',
            textAlign: 'left',
          }}
        >
          Cart Page
        </h1>
        <p className="mt-4 text-lg">
          Home &gt;<span className='text-[#BE740A]'>Cart Page</span>
        </p>
      </div>

      <div className='flex justify-center mt-20 mb-10'>
        <div className='w-[1156px] h-[45px] bg-[#4C3F35] text-white rounded-lg flex items-center p-2'>
          <input type="checkbox" className='mr-2 accent-white' checked />
          <span>Shipping costs updated</span>
        </div>
      </div>
    {cart.length > 0 ? (
  <div className='flex justify-center mt-5'>
    <div className="overflow-x-auto">
      <table className="border-collapse w-[1156px]">
        <thead className='h-[76px]'>
          <tr className="bg-[#F2F2F2] border">
            <th className="p-2">Image</th>
            <th className="p-2">Product Name</th>
            <th className="p-2">BagSize & Quantity</th>
            <th className="p-2">MRP/kg</th>
            <th className="p-2">Total</th>
            <th className="p-2">Remove</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((item, index) => {
            const discountedPrice = item.discount ? item.price * (1 - item.discount / 100) : item.price;
            const itemTotal = item.selections.reduce((itemTotal, selection) => {
              const sizeInKg = parseInt(selection.size);
              return itemTotal + (discountedPrice * sizeInKg * selection.quantity);
            }, 0);
            return (
              <tr key={index} className="text-center border h-[76px]">
                <td className="flex justify-center p-2">
                  <img src={item.selectedImages[0]} alt={item.productName} className="w-[89px] h-[89px] mr-2 rounded-lg" />
                </td>
                <td className="p-2">{item.productName}</td>
                <td className="p-2">
                  {item.selections.map((selection, idx) => (
                    <div key={idx}>
                      {selection.size} &times; {selection.quantity}
                    </div>
                  ))}
                </td>
                <td className="p-2">
                  {item.discount ? (
                    <>
                      <del>₹{item.price}</del> ₹{discountedPrice.toFixed(2)}
                      <span className="ml-2 text-sm text-gray-500">({item.discount}% off)</span>
                    </>
                  ) : (
                    <>₹{item.price}</>
                  )}
                </td>
                <td className="p-2">
                  {itemTotal.toFixed(2)} ₹
                </td>
                <td className="p-2">
                  <button className="text-red-500 hover:text-red-700" onClick={() => removeFromCart(item._id)}>
                    <AiOutlineDelete />
                  </button>
                </td>
              </tr>
            );
          })}
          <tr className="text-center border h-[20px]">
            <td colSpan="1" className="p-2 border border-gray-300 text-[14px]" style={{ fontFamily: 'Lexend', fontSize: '14px', fontWeight: 400 }}>Subtotal:</td>
            <td className="p-2"></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{calculateTotal().toFixed(2)} ₹</td>
          </tr>
          <tr className="text-center border h-[20px]">
            <td colSpan="1" className="p-2 border border-gray-300 text-[14px]" style={{ fontFamily: 'Lexend', fontSize: '14px', fontWeight: 400 }}>Tax:</td>
            <td className="p-2"></td>
            <td></td>
            <td></td>
            <td></td>
            <td>0</td> {/* Assuming tax is 0 for simplicity */}
          </tr>
          <tr className="text-center border h-[20px]">
            <td colSpan="1" className="p-2 border border-gray-300 text-[14px]" style={{ fontFamily: 'Lexend', fontSize: '14px', fontWeight: 400 }}>Shipping:</td>
            <td className="p-2"></td>
            <td></td>
            <td></td>
            <td></td>
            <td>0</td> {/* Assuming shipping is 0 for simplicity */}
          </tr>
          <tr className="text-center border h-[20px]">
            <td colSpan="1" className="p-2 border border-gray-300 text-[14px]" style={{ fontFamily: 'Lexend', fontSize: '14px', fontWeight: 400 }}>Coupon:</td>
            <td className="p-2"></td>
            <td></td>
            <td></td>
            <td></td>
            <td>0</td> {/* Assuming coupon discount is 0 for simplicity */}
          </tr>
          <tr className="text-center border h-[20px] font-bold">
            <td colSpan="1" className="p-2 font-bold border border-gray-300 text-[18px]" style={{ fontFamily: 'Lexend', fontWeight: 700 }}>Total:</td>
            <td className="p-2"></td>
            <td></td>
            <td></td>
            <td></td>
            <td className='text-[#087E27] text-[18px]'>{calculateTotal().toFixed(2)} ₹</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
      ) : (
          <>
           <div className="flex justify-center items-center h-[200px]">
            <img src={CartEmpty} alt="Wheat" className="h-[200px] w-[150px]" />
            
          </div>
          <p className='mb-5 text-center text-[20px] font-serif'>No Product Found!</p>
          </>
 
)}

     
      {
        cart.length > 0 && (
          <div className='flex justify-center mt-10 mb-20'>
  <button
    className={`w-[214px] h-[54px] rounded-[27px] text-white ${cart.length === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#FF9F00]'}`}
    onClick={NavigateToAddDeliveryAddress}
    disabled={cart.length === 0} // Disable button if cart is empty
  >
    PROCEED TO CHECKOUT
  </button>
</div>
        )
      }
     
     

    </div>
  )
}

export default CartPage;
