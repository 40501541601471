import React, { useEffect, useState } from 'react';
import { FaEdit } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';
import Swal from 'sweetalert2';

function ProfilePage() {
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  // State for each profile field
  const [contactPerson, setContactPerson] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [email, setEmail] = useState('');
  const [shopName, setShopName] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [fassaiNumber, setFassaiNumber] = useState('');
  const [shopAddress, setShopAddress] = useState('');

  // Validation error states
  const [errors, setErrors] = useState({});

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    const dealerInfo = JSON.parse(localStorage.getItem('dealerInfo'));
    const shopId = dealerInfo?.shopId;

    if (shopId) {
      fetch(`${BaseUrl}/Dealer/DealerDetails/${shopId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((responseData) => {
          const dealerData = responseData.data;
          setContactPerson(dealerData.contactPerson || '');
          setContactNumber(dealerData.contactNumber || '');
          setEmail(dealerData.email || '');
          setShopName(dealerData.shopName || '');
          setGstNumber(dealerData.gstNumber || '');
          setFassaiNumber(dealerData.FSSAINumber || '');
          setShopAddress(dealerData.ShopAddress || '');
        })
        .catch((error) => {
          console.error('Error fetching dealer details:', error);
        });
    } else {
      navigate("/");
    }
  }, [navigate]);

  // Validation function
  const validateFields = () => {
    const newErrors = {};

    if (!contactPerson.trim()) newErrors.contactPerson = 'Contact Person is required';
    if (!contactNumber) newErrors.contactNumber = 'Contact Number is required';
    else if (!/^\d{10}$/.test(contactNumber)) newErrors.contactNumber = 'Contact Number must be 10 digits';
    
    if (!email.trim()) newErrors.email = 'Email is required';
    else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) newErrors.email = 'Invalid Email format';
    
    if (!shopName.trim()) newErrors.shopName = 'Shop Name is required';
    if (!shopAddress.trim()) newErrors.shopAddress = 'Shop Address is required';
    
    if (!gstNumber.trim()) newErrors.gstNumber = 'GST Number is required';
    else if (!/^[0-9a-zA-Z]{15}$/.test(gstNumber)) newErrors.gstNumber = 'GST Number must be 15 characters';
    
    if (!fassaiNumber.trim()) newErrors.fassaiNumber = 'FASSAI Number is required';
    else if (!/^[a-zA-Z0-9]{14}$/.test(fassaiNumber)) {
  newErrors.fassaiNumber = 'FASSAI Number must be exactly 14 alphanumeric characters';
}

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle saving updated profile data
  const handleSave = () => {
    if (!validateFields()) {
      return; // If validation fails, don't proceed
    }

    const dealerInfo = JSON.parse(localStorage.getItem('dealerInfo'));
    const shopId = dealerInfo?.shopId;

    const updatedData = {
      contactPerson,
      contactNumber,
      email,
      shopName,
      gstNumber,
      FSSAINumber: fassaiNumber,
      ShopAddress: shopAddress,
    };

    fetch(`${BaseUrl}/Dealer/update/${shopId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to update profile');
        }
        return response.json();
      })
      .then((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Profile Updated!',
          text: 'Your profile has been updated successfully.',
        });
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Error updating profile:', error);
        Swal.fire({
          icon: 'error',
          title: 'Update Failed',
          text: 'There was an error updating your profile. Please try again.',
        });
      });
  };

  return (
   <div className="min-h-screen p-6 bg-gray-100">
  <Link to='/' className="mb-4 text-lg text-gray-600">
    &lt; Back
  </Link>
  <div className='flex items-center justify-between p-5 mb-6'>
    <h1 className="text-2xl font-bold text-gray-800">My Profile</h1>
    <FaEdit className='w-6 h-6 text-gray-600 cursor-pointer hover:text-gray-800' title='Update Profile' onClick={toggleEdit} />
  </div>

  <div className='max-w-4xl p-6 mx-auto bg-white rounded-lg shadow-md'>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      
      {/* Contact Person */}
      <div>
        <label className="font-semibold text-gray-700">Contact Person</label>
        {isEditing ? (
          <input
            type="text"
            value={contactPerson}
            onChange={(e) => setContactPerson(e.target.value)}
            className={`w-full p-2 mt-2 bg-gray-100 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 ${errors.contactPerson ? 'border-red-500' : ''}`}
          />
        ) : (
          <p className="mt-2 text-gray-600">{contactPerson}</p>
        )}
        {errors.contactPerson && <p className="mt-1 text-sm text-red-500">{errors.contactPerson}</p>}
      </div>

      {/* Contact Number */}
      <div>
        <label className="font-semibold text-gray-700">Contact Number</label>
        {isEditing ? (
          <input
            type="text"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            onKeyPress={(e) => {
              if (!/^\d$/.test(e.key)) {
                e.preventDefault(); // Prevents entering non-numeric characters
              }
            }}
            maxLength={10} // Restricting max length to 10 digits
            className={`w-full p-2 mt-2 bg-gray-100 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 ${errors.contactNumber ? 'border-red-500' : ''}`}
          />
        ) : (
          <p className="mt-2 text-gray-600">{contactNumber}</p>
        )}
        {errors.contactNumber && <p className="mt-1 text-sm text-red-500">{errors.contactNumber}</p>}
      </div>

      {/* Email */}
      <div>
        <label className="font-semibold text-gray-700">Email ID</label>
        {isEditing ? (
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`w-full p-2 mt-2 bg-gray-100 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 ${errors.email ? 'border-red-500' : ''}`}
          />
        ) : (
          <p className="mt-2 text-gray-600">{email}</p>
        )}
        {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
      </div>

      {/* Shop Name */}
      <div>
        <label className="font-semibold text-gray-700">Shop Name</label>
        {isEditing ? (
          <input
            type="text"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
            className={`w-full p-2 mt-2 bg-gray-100 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 ${errors.shopName ? 'border-red-500' : ''}`}
          />
        ) : (
          <p className="mt-2 text-gray-600">{shopName}</p>
        )}
        {errors.shopName && <p className="mt-1 text-sm text-red-500">{errors.shopName}</p>}
      </div>

      {/* Shop Address */}
      <div>
        <label className="font-semibold text-gray-700">Shop Address</label>
        {isEditing ? (
          <textarea
            value={shopAddress}
            onChange={(e) => setShopAddress(e.target.value)}
            className={`w-full p-2 mt-2 bg-gray-100 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 ${errors.shopAddress ? 'border-red-500' : ''}`}
            rows="3"
          />
        ) : (
          <p className="mt-2 text-gray-600">{shopAddress}</p>
        )}
        {errors.shopAddress && <p className="mt-1 text-sm text-red-500">{errors.shopAddress}</p>}
      </div>

      {/* GST Number */}
      <div>
        <label className="font-semibold text-gray-700">GST Number</label>
        {isEditing ? (
          <input
            type="text"
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
            onKeyPress={(e) => {
              if (!/^\d$/.test(e.key)) {
                e.preventDefault(); // Prevents entering non-numeric characters
              }
            }}
            maxLength={15} // Restricting max length to 15 digits
            className={`w-full p-2 mt-2 bg-gray-100 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 ${errors.gstNumber ? 'border-red-500' : ''}`}
          />
        ) : (
          <p className="mt-2 text-gray-600">{gstNumber}</p>
        )}
        {errors.gstNumber && <p className="mt-1 text-sm text-red-500">{errors.gstNumber}</p>}
      </div>

      {/* FASSAI Number */}
      <div>
        <label className="font-semibold text-gray-700">FASSAI Number</label>
        {isEditing ? (
          <input
            type="text"
            maxLength={14}
            value={fassaiNumber}
            onChange={(e) => setFassaiNumber(e.target.value)}
            className={`w-full p-2 mt-2 bg-gray-100 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 ${errors.fassaiNumber ? 'border-red-500' : ''}`}
          />
        ) : (
          <p className="mt-2 text-gray-600">{fassaiNumber}</p>
        )}
        {errors.fassaiNumber && <p className="mt-1 text-sm text-red-500">{errors.fassaiNumber}</p>}
      </div>
      
    </div>

    {isEditing && (
      <div className="mt-6 text-center">
        <button
          onClick={handleSave}
          className="px-4 py-2 font-semibold text-white bg-blue-600 rounded-lg hover:bg-blue-500"
        >
          Save
        </button>
      </div>
    )}
  </div>
</div>

  );
}

export default ProfilePage;
