import React, { useEffect, useState } from "react";
import { FaArrowDownLong } from "react-icons/fa6";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import OrderConfirmModel from "../../Componenet/Models/OrderConfirm";
import { BaseUrl } from "../../ContextApi/Url/BaseUrl";
import Swal from 'sweetalert2';
import Loader from "../../Componenet/Loader";

function PaymentMethod() {
   const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(2);
  const [OrderConfirm, setOrderConfirm] = useState(false);
  const location = useLocation();
   const [loading, setLoading] = useState(false);
    const { products, totalAmount, shippingCharges, savedAmount,addressData,bagSizeAndQnt,productTotals } = location.state || {};
console.log(addressData,"addressData")
  const [bankName, setBankName] = useState("");
  const [accountHolderNameBank, setAccountHolderNameBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [paymentAmountBank, setPaymentAmountBank] = useState("");
  const [bankNameCheque, setBankNameCheque] = useState("");
  const [accountHolderNameCheque, setAccountHolderNameCheque] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [paymentAmountCheque, setPaymentAmountCheque] = useState("");
 
   // State for errors
  const [errors, setErrors] = useState({
    bankName: "",
    accountHolderNameBank: "",
    accountNumber: "",
    ifscCode: "",
    paymentAmountBank: ""
  });
  
  // Make the API call or further processing here
  // Example:
  // await api.placeOrder(orderData);
  const [dealerInfo, setDealerInfo] = useState(null);
  console.log(dealerInfo,"dealerinfo")
  const [CurrentTab, setCurrentTab] = useState("Payment Option");
  const [selectedOption, setSelectedOption] = useState("");
  console.log(selectedOption,"selectedOption")
  const steps = [
    { id: 1, name: "Address" },
    { id: 2, name: "Order Details" },
    { id: 3, name: "Payment Option" },
  ];
  const [visibleProducts, setVisibleProducts] = useState(2); // Set initial number of visible products

  // Function to handle showing more items
  const showMoreProducts = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 5); // Show 5 more items when clicking "Show More"
  };
    useEffect(() => {
  // Reset errors when selectedOption changes
  setErrors({
    bankName: "",
    accountHolderNameBank: "",
    accountNumber: "",
    ifscCode: "",
    paymentAmountBank: ""
  });
}, [selectedOption]);
   

    const ConfirmOrderModel = () => {
        setOrderConfirm(true);
    };
    // const ConfirmOrderModel = () => {
    //     setOrderConfirm(true)
    // }
     const ConfirmOrderClose = () => {
        setOrderConfirm(false)
  }
  const handleBackClick = () => {
    navigate(-1); // This navigates to the previous route
  };
  //  const orderData = {
  //   orderId: "", // You can generate or assign an order ID here
  //   Total: totalAmount, // Total amount from state
  //   PaymentDoneAmount: "", // You can set this based on payment status
  //   PaymentMethod: selectedOption, // Assuming you have a selected payment method
  //   Duepayment: "", // You can calculate this if needed
  //   customerInfo: {
  //     CustomerName: addressData.name,
  //     ShopName: addressData.shopName || "", // If applicable
  //     ContactNo: addressData.mobile,
  //     EmailID: addressData.email || "", // If applicable
  //     Billing_Address: `${addressData.address}, ${addressData.city}, ${addressData.state} - ${addressData.pincode}`,
  //     Shipping_Address: `${addressData.shippingAddress || addressData.address}, ${addressData.city}, ${addressData.state} - ${addressData.pincode}`
  //   },
  //   ProductDetails: products.map((product) => ({
  //     ProductID: product._id,
  //     OrderDate: new Date().toISOString().split("T")[0],
  //     ProductName: product.productName,
  //     MRP: product.price,
  //     discount: product.discount,
  //     selection: bagSizeAndQnt.find(item => item.productId === product._id)?.selection || [], // Match product ID and get selection
  //     dispatchShippingDetails: {
  //       DispatchID: "",
  //       EstimatedDeliveryDate: "",
  //       DriverName: "",
  //       ContactNumber: "",
  //       VehicleNumber: "",
  //       TaxAndDuties: "",
  //       Insurance: "",
  //       RefundStatus: "",
  //       RefundReasons: "",
  //       RejectRefundReasons: "",
  //       RefundDate: "",
  //       OrderStatus: "Pending",
  //       weight: "", // You might need to calculate this
  //       Category: product.category,
  //       DispatchStatus: "",
  //       OrderCancelReason: "",
  //       OrderPendingReason: ""
  //     },
  //     OrderTrackingDetails: {
  //       Place: true,
  //       PlaceNote: "Order placed successfully.",
  //       Shipped: false,
  //       ShippedNote: "",
  //       Delivered: false,
  //       DeliveredNote: "",
  //       Out_for_Delivery: false,
  //       Out_for_Delivery_Note: ""
  //     },
  //     SupplierInfo: {
  //       FactoryName: product.supplierName,
  //       FactoryAddress: product.supplierCity,
  //       FactoryContact: product.supplierContactNumber
  //     },
  //     commission: product.Commission
  //   })),
  //   // shippingCharges: shippingCharges, 
  //   // savedAmount: savedAmount 
  // };
 
    const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };
  
  
   const handleInputChangeForCheckAmount = (e) => {
    const value = e.target.value;
    
    // Allow only numbers and check for validity
    const numericValue = value.replace(/[^0-9.]/g, '');
    
    if (parseFloat(numericValue) > totalAmount) {
      setErrors({
        ...errors,
        paymentAmountCheque: `Amount cannot exceed ${totalAmount}`
      });
    } else {
      setErrors({ ...errors, paymentAmountCheque: '' });
      setPaymentAmountCheque(numericValue);
    }
  };
  const handleInputChangeForcheckNumber = (e, setState) => {
    // Get the input value
    const value = e.target.value;

    // Filter to allow only digits
    const numericValue = value.replace(/[^0-9]/g, '');

    // Update state with filtered numeric value
    setState(numericValue);

    // Simple validation for required field
    if (numericValue === '') {
      setErrors({ ...errors, chequeNumber: 'Cheque Number is required' });
    } else {
      setErrors({ ...errors, chequeNumber: '' });
    }
  };
useEffect(() => {
    // Retrieve data from localStorage
    const storedDealerInfo = localStorage.getItem('dealerInfo');
    
    if (storedDealerInfo) {
      // If data exists, parse it and set it to state
      setDealerInfo(JSON.parse(storedDealerInfo));
    } else {
      // If no data found, show an alert
      window.alert('Please log in to access this information.');
    }
  }, []);
   const validateFieldsForBank = () => {
    let isValid = true;
    const newErrors = {
      bankName: "",
      accountHolderNameBank: "",
      accountNumber: "",
      ifscCode: "",
      paymentAmountBank: ""
    };

    if (!bankName) {
      newErrors.bankName = "Bank Name is required";
      isValid = false;
    }
    if (!accountHolderNameBank) {
      newErrors.accountHolderNameBank = "Account Holder Name is required";
      isValid = false;
    }
    if (!accountNumber) {
      newErrors.accountNumber = "Account Number is required";
      isValid = false;
    }
    if (!ifscCode) {
      newErrors.ifscCode = "IFSC Code is required";
      isValid = false;
    }
    if (!paymentAmountBank) {
      newErrors.paymentAmountBank = "Payment  Amount is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateFieldsForCheque = () => {
  const errors = {};
  if (!bankNameCheque) errors.bankNameCheque = 'Bank Name is required';
  if (!accountHolderNameCheque) errors.accountHolderNameCheque = 'Account Holder Name is required';
  if (!chequeNumber) errors.chequeNumber = 'Cheque Number is required';
  if (!paymentAmountCheque || isNaN(paymentAmountCheque)) errors.paymentAmountCheque = 'Valid Payment  Amount is required';

  setErrors(errors);
  return Object.keys(errors).length === 0;
};
  const confirmOrder = async () => {
    setLoading(true)
    let paymentDetails = {};
    if (selectedOption === "bank") {
      if (!validateFieldsForBank()) {
        setLoading(false)
        return;
        
      }
      
    }
    if (selectedOption === "cheque") {
      if (!validateFieldsForCheque()) {
        setLoading(false)
        return;
      }
    }
    if (selectedOption === "bank") {
      paymentDetails = {
        paymentMethod: "Bank",
        bankDetails: {
          bankName,
          accountHolderName: accountHolderNameBank,
          accountNumber,
          ifscCode,
          paymentAmount: paymentAmountBank,
        },
      };
    } else if (selectedOption === "cheque") {
      paymentDetails = {
        paymentMethod: "Cheque",
        chequeDetails: {
          bankName: bankNameCheque,
          accountHolderName: accountHolderNameCheque,
          chequeNumber,
          paymentAmount: paymentAmountCheque,
        },
      };
    } else if (selectedOption === "cash") {
      paymentDetails = {
        paymentMethod: "Cash",
        totalAmount,
      };
    }

     let paymentDoneAmount = 0;
    let duePayment = totalAmount;

    if (selectedOption !== "cash") {
        paymentDoneAmount = (parseFloat(paymentAmountBank) || 0) + (parseFloat(paymentAmountCheque) || 0);
        duePayment = totalAmount - paymentDoneAmount;
    }

    const orderData = {
        orderId: `ORD-${Date.now()}-${Math.floor(Math.random() * 10000)}`, // Generate a unique 
      Total: totalAmount,
        ShopId: dealerInfo.shopId,
        PaymentDoneAmount: paymentDoneAmount, // Set the payment done amount
       PaymentMethod: "",
      Duepayment: totalAmount, 
        customerInfo: {
          CustomerName: addressData.name,
          ShopName: dealerInfo.shopName || "",
          ShopID: dealerInfo.shopName || "",
          ContactNo: addressData.mobile,
          EmailID: dealerInfo.email || "",
          Billing_Address:addressData.address,
          Shipping_Address:addressData.address,
           gstNumber: dealerInfo.gstNumber || "",
              FSSAINumber: dealerInfo.FSSAINumber || "",
        },
       ProductDetails: products.map((product) => {
            const matchingProductTotal = productTotals.find(item => item.productId === product._id);
            return {
                ProductID: product.productCode,
                  productTotalAmount: matchingProductTotal ? String(matchingProductTotal.productTotal) : "0", // Convert to string 
                OrderDate: new Date().toISOString().split("T")[0],
                ProductName: product.productName,
                MRP: product.price,
                discount: product.discount,
                selection: bagSizeAndQnt.find(item => item.productId === product._id)?.selection || [],
                dispatchShippingDetails: {
                    DispatchID: "",
                    EstimatedDeliveryDate: "",
                    DriverName: "",
                    ContactNumber: "",
                    VehicleNumber: "",
                    TaxAndDuties: "",
                    Insurance: "",
                    RefundStatus: "",
                    RefundReasons: "",
                    RejectRefundReasons: "",
                    RefundDate: "",
                    OrderStatus: "Pending",
                    weight: "", // Calculate if needed
                    Category: product.category,
                    DispatchStatus: "",
                    OrderCancelReason: "",
                    OrderPendingReason: "",
                },
                OrderTrackingDetails: {
                    Place: true,
                    PlaceNote: "Order placed successfully.",
                    Shipped: false,
                    ShippedNote: "",
                    Delivered: false,
                    DeliveredNote: "",
                    Out_for_Delivery: false,
                    Out_for_Delivery_Note: "",
                    payment: false,
                },
                SupplierInfo: {
                    FactoryName: product.supplierName,
                    FactoryAddress: product.supplierCity,
                    FactoryContact: product.supplierContactNumber,
                  FactoryId: product.FactoryId,
                    FactoryFSSAI: product.FactoryFSSAI,
                      FactoryGST: product.FactoryGST,
                },
                commission: product.Commission,
                selectedImages: product.selectedImages,
            };
        }),
      };
 const token = localStorage.getItem('authtoken'); 
     try {
        const response = await fetch(`${BaseUrl}/Order/CreateOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
            body: JSON.stringify(orderData),
        });

       if (!response.ok) {
          setLoading(false)
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log("Order created successfully:", data);
    Swal.fire({
            title: 'Order Confirmed!',
            text: 'Your order has been placed successfully.',
            icon: 'success',
            confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
              setLoading(false)
                // Navigate to the Shop page after the alert is confirmed
                navigate('/Shop');
            }
        });
        // Show confirmation modal or navigate
        // setOrderConfirm(true);
    } catch (error) {
       Swal.fire({
            title: 'Error!',
            text: 'There was a problem with placing your order. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
              setLoading(false)
                // Navigate to the Shop page after the alert is confirmed
                navigate('/Shop');
            }
        });;
    }
};
 const handleAmountChange = (e) => {
    const value = e.target.value;
    
    // Allow only numbers and check for validity
    const numericValue = value.replace(/[^0-9.]/g, '');
    
    if (parseFloat(numericValue) > totalAmount) {
      setErrors({
        ...errors,
        paymentAmountBank: `Amount cannot exceed ${totalAmount}`
      });
    } else {
      setErrors({ ...errors, paymentAmountBank: '' });
      setPaymentAmountBank(numericValue);
    }
  };
 const handleAccountNumberChange = (e) => {
    const value = e.target.value;
    
    // Allow only numbers
    const numericValue = value.replace(/[^0-9]/g, '');
    
    // You can add validation for length or other criteria here if needed
    setAccountNumber(numericValue);

    // Example validation: Set an error if the account number is empty or invalid
    if (numericValue === '') {
      setErrors({
        ...errors,
        accountNumber: 'Account number is required'
      });
    } else {
      setErrors({ ...errors, accountNumber: '' });
    }
  };
  return (
    <div>
     <div className="flex gap-2 p-5 md:pl-28">
        <IoArrowBackCircleSharp  className="h-10 w-10 mt-1.5 cursor-pointer hover:scale-110"   onClick={handleBackClick}/>
        {/* <p className="text-gray-500 cursor-pointer text-16">Back</p> */}
      </div>
      <div className="flex-col items-center hidden mt-10 md:flex md:visible">
  <div className="flex w-full max-w-4xl px-5 md:px-0">
    {steps.map((step, index) => (
      <div key={step.id} className="relative flex items-center flex-1">
        {index > 0 && (
          <div
            className={`${
              index <= currentStep ? "bg-yellow-500" : "bg-gray-300"
            } h-1 flex-grow`}
          />
        )}
        <div className="flex flex-col items-center">
          <div
            className={`${
              index <= currentStep
                ? "bg-yellow-500 text-white"
                : "bg-gray-300 text-gray-500"
            } w-12 h-12 rounded-full flex items-center justify-center`}
          >
            {index + 1}
          </div>
          <span
            className={`mt-2 ${
              index <= currentStep ? "text-yellow-500" : "text-gray-500"
            }`}
          >
            {step.name}
          </span>
        </div>
        {index < steps.length - 1 && (
          <div
            className={`${
              index < currentStep ? "bg-yellow-500" : "bg-gray-300"
            } h-1 flex-grow`}
          />
        )}
      </div>
    ))}
  </div>
</div>

     

     <div className="flex flex-col justify-center gap-5 mt-10 mb-5 md:flex-row">
  <div className="w-full md:w-[700px]  p-2 md:p-0">
    <div className="h-[300px] overflow-auto scrollbar-hide">
      {products.slice(0, visibleProducts).map((value) => {
        const product = bagSizeAndQnt.find((item) => item.productId === value._id);
        const selections = product ? product.selection : [];

        return (
          <div key={value._id} className="w-full max-w-[650px] h-[183px] border rounded-lg shadow-lg flex items-center gap-6 p-4 mb-4">
            <div className="relative flex-shrink-0">
              <img
                src={value.selectedImages[0]}
                alt={value.productName}
                className="md:w-[150px] md:h-[150px] object-cover rounded-lg w-[100px] h-[100px]"
              />
              {value.discount && (
                <button
                  className="absolute top-0 left-0 bg-[#735944] text-white p-1 rounded-lg"
                  style={{ fontFamily: "DM Sans, sans-serif", fontSize: "14px", fontWeight: 700 }}
                >
                  {`${value.discount}% OFF`}
                </button>
              )}
            </div>
<div className="md:gap-5 md:flex">
 <div className="flex flex-col flex-grow">
              <p className="font-lexend text-[20px] font-semibold leading-[25px] text-left">
  {value.productName.length > 10
    ? `${value.productName.substring(0, 10)}...`
    : value.productName}
</p>


              <div className="flex items-center mt-5 space-x-2">
                {value.discount ? (
                  <>
                    <span className="text-black" style={{ fontFamily: "DM Sans, sans-serif", fontSize: "18px", fontWeight: 700 }}>
                      Price:
                    </span>
                    <p className="text-[#6D6D6D] line-through" style={{ fontFamily: "DM Sans, sans-serif", fontSize: "14px", fontWeight: 700 }}>
                      ₹{value.price.toFixed(2)}
                    </p>
                    <p className="text-[#6D6D6D]" style={{ fontFamily: "DM Sans, sans-serif", fontSize: "14px", fontWeight: 700 }}>
                      ₹{(value.price * (1 - value.discount / 100)).toFixed(2)}
                    </p>
                  </>
                ) : (
                  <p className="text-black" style={{ fontFamily: "DM Sans, sans-serif", fontSize: "18px", fontWeight: 700 }}>
                    Price: ₹{value.price.toFixed(2)}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-col justify-between">
              <div>
                <p className="text-[18px] font-semibold">
                  Quantity:
                  {selections.length > 0 ? (
                    selections.map((selection, sIdx) => (
                      <span key={sIdx} className="ml-1 text-[15px]">
                        {selection.size}-{selection.quantity},
                      </span>
                    ))
                  ) : (
                    <span className="ml-1 text-[15px]">No size/quantity details available</span>
                  )}
                </p>
              </div>
              <p className="text-[18px] font-semibold mt-5">
                Quality: <span className="ml-1 text-[15px]">{value.qualityVariety}</span>
              </p>
            </div>

              
</div>
           
          </div>
        );
      })}
    </div>

    {/* Show More Button */}
    {visibleProducts < products.length && (
      <div className="flex justify-center mt-4">
        <button
          onClick={showMoreProducts}
          className="flex gap-1 px-4 py-2 rounded-lg"
          style={{ fontFamily: "DM Sans, sans-serif", fontSize: "16px", fontWeight: 700 }}
        >
          <span>Show More</span> <span><FaArrowDownLong className="mt-1" /></span>
        </button>
      </div>
    )}
  </div>

  <div className=" w-full md:w-[372px] h-[302px] rounded-[20px] shadow-lg border p-2 ">
    <p
      className="p-5"
      style={{
        fontFamily: "Lexend",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "20px",
        textAlign: "left",
      }}
    >
      PRICE DETAILS
    </p>
    <hr className="text-black" />
    <div className="p-5">
      <p style={{ display: "flex", justifyContent: "space-between" }}>
        <span className="text-[#6D6D6D]">Price ({products.length} item)</span>
        <span>₹ {parseFloat(totalAmount).toFixed(2)}</span>
      </p>
      <p
        style={{ display: "flex", justifyContent: "space-between" }}
        className="mt-2"
      >
        <span className="text-[#6D6D6D]">Shipping Charges</span>
        <span>₹ {shippingCharges}</span>
      </p>
    </div>
    <hr className="text-black" />
    <div className="pt-2 pl-5 pr-5">
      <p
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "16px",
          fontWeight: 500,
          fontFamily: "Lexend",
        }}
        className="mt-2"
      >
        <span className="text-[#222222] text-[16px] font-[500]">
          Total Payable
        </span>
        <span>₹ {parseFloat(totalAmount).toFixed(2)}</span>
      </p>
    </div>
    <hr className="mt-2 text-black" />
    <p
      className="text-[#3E8E3C] text-center text-[14px] mt-4"
      style={{
        fontFamily: "DM Sans, sans-serif",
      }}
    >
      Your Total Saving on this order ₹{savedAmount}
    </p>
  </div>
</div>

      <div>
           <div className="flex justify-center p-10 mt-5 mb-2">
  <button
    className="w-[306px] h-[50px] bg-[#FF9F00] text-white rounded-[26.5px] relative overflow-hidden"
    onClick={confirmOrder}
  >
    <span className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-60 transform translate-x-[-150%] animate-shine"></span>
    PLACE ORDER
  </button>
</div>

        </div>
          {
              selectedOption === 'cash' && (
                   <div className="flex justify-center mt-10 mb-20">
        <button className="w-[176px] h-[53px] bg-[#FF9F00] text-white rounded-[26.5px]" onClick={confirmOrder}>
          PLACE ORDER
        </button>
      </div>
              )
          }
      {/* <Or isModalOpen={OrderConfirm} onClose={ConfirmOrderClose}/> */}
           {loading && <Loader loading={loading}  />}
          <OrderConfirmModel isModalOpen={OrderConfirm} onClose={ConfirmOrderClose}/>
    </div>
  );
}

export default PaymentMethod;
