import React from 'react';
import AboutBg from '../../asset/AboutBg.png';
function PrivacyPolicy() {
  return (
    <div>
      <div
      className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4 "
      style={{ backgroundImage: `url(${AboutBg})` }}
    >
      <h1
        className="text-[#222222] md:text-[50px] text-[30px]  "
        style={{
          fontFamily: 'Lexend',
          // fontSize: '50px',
          fontWeight: 500,
          lineHeight: '40px',
          textAlign: 'left',
              }}

      >
      Privacy And Policy
      </h1>
      <p className="mt-4 text-lg ">Home &gt;<span className='text-[#BE740A]'>  Privacy And Policy</span></p>
          </div>
          <div className='flex justify-center p-5 mt-10 mb-10' >
               <div >
            <p className="font-lexend text-[20px] font-medium leading-[40px] text-left">
 Privacy Policy for Aakar Canvassing
</p>

<p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
Aakar Canvassing (“we,” “our,” or “us”) values your privacy and is committed to protecting your personal and business<br></br> information. This Privacy Policy explains how we collect, use, and safeguard the information you provide while using our<br></br> services. By interacting with us, you consent to the practices outlined in this policy.
          </p>
      <hr className="mt-5 border-t-2 border-black" />

          <p className='mt-5 font-lexend text-[20px] font-medium leading-[40px] '>1. Information We Collect</p>
          
              <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
  We may collect the following types of information:
          </p>
          <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
  1.1 Personal Information:
          </p>
          <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">Name, address, email address, phone number, and other contact details.</p>
          </div>
              
<div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
  1.2 Business Information
          </p>
          <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">Factory or dealer name, business registration details, transaction details, product preferences, and payment information..</p>
          </div>
<div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
 1.3 Usage Information
          </p>
          <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">Data related to your interactions with our website or services, such as IP address, browser type, and access times.</p>
          </div>
          <hr className="mt-5 border-t-2 border-black" />
          <div className='mt-5'>
             <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold">
  2. How We Use Your Information
            </p>
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
  We may collect the following types of information:
            </p>
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
 2.1 Facilitate transactions between factories and dealers.<br></br>
2.2 Calculate and collect commissions.<br></br>
2.3 Communicate with you regarding transactions, updates, and support.<br></br>
2.4 Improve our services, processes, and user experience.<br></br>
2.5 Comply with legal and regulatory requirements.<br></br>
          </p>
          </div>
           <hr className="mt-5 border-t-2 border-black" />
          <div>
              <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold mt-5">
 3. Sharing of Information
            </p>
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
  We do not sell, rent, or trade your information to third parties. However, we may share information in the following cases:
            </p>
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
3.1 With Transaction Parties: To facilitate deals between factories and dealers.<br></br>
3.2 With Service Providers: Third-party vendors assisting us with operations, such as payment processing and IT support, <br></br>
      are bound by confidentiality agreements.<br></br>
3.3 For Legal Compliance: If required by law, court order, or governmental regulations.<br></br>
            </p>
            
          </div>
          <hr className="mt-5 border-t-2 border-black" />
           <div>
              <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold mt-5">
 4. Data Protection
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
4.1 We implement reasonable security measures, including encryption, firewalls, and secure storage, to<br></br> protect your information from unauthorized access, alteration, disclosure, or destruction.<br></br>
4.2 While we strive to protect your data, no system is 100% secure, and we cannot guarantee absolute security.<br></br>
            </p>
            
          </div>
          <hr className="mt-5 border-t-2 border-black" />
           <div>
              <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold mt-5">
 5. Your Rights
            </p>
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
 You have the following rights regarding your personal information:
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
5.1 Access and Correction: You may request access to or correction of your information.<br></br>
5.2 Opt-Out: You may opt out of receiving promotional communications from us.<br></br>
5.3 Data Deletion: You can request the deletion of your personal information, subject to our legal <br></br>obligations.
            </p>
            
          </div>
          <hr className="mt-5 border-t-2 border-black" />
          <div>
              <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold mt-5">
6. Retention of Information
            </p>
            
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
We retain your information as long as necessary to fulfill the purposes outlined in this policy, comply<br></br> with legal requirements, and resolve disputes.
            </p>
            
          </div>
           <hr className="mt-5 border-t-2 border-black" />
               <div>
              <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold mt-5">
7. Cookies and Tracking Technologies
            </p>
            
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
Our website may use cookies and similar technologies to enhance your experience and gather data on<br></br> website usage. You may disable cookies through your browser settings, but doing so may affect <br></br>website functionality.
            </p>
            
          </div>
          <hr className="mt-5 border-t-2 border-black" />
          <div>
              <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold mt-5">
8. Third-Party Links
            </p>
            
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
Our services may include links to third-party websites. We are not responsible for the privacy practices<br></br> of these external sites and encourage you to review their privacy policies.
            </p>
            
          </div>
          <hr className="mt-5 border-t-2 border-black" />
           <div>
              <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold mt-5">
9. Updates to This Privacy Policy
            </p>
            
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
We may update this Privacy Policy periodically. Changes will be communicated via email or website <br></br>updates. Continued use of our services after such updates constitutes your <br></br>acceptance of the revised policy.
            </p>
            
          </div>
          <hr className="mt-5 border-t-2 border-black" />
          <div>
              <p className="font-dmsans text-[20px]  leading-[26px] text-left font-semibold mt-5">
10. Contact Us
            </p>
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
If you have questions or concerns about this Privacy Policy or your information, please contact us at:
            </p>
             
             <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-2">
Email: anandtalera111@gmail.com<br></br>
Phone: (+91) 99214 55999 |  (+91) 81498 41266<br></br>
Address: : Office No.3, 5th Floor, Siddhi Pooja Business Square, Sharanpur Road, Nashik - 422005<br></br>
            </p>
            
          </div>
          <hr className="mt-5 border-t-2 border-black" />
           <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D] mt-5">
By using our services, you acknowledge that you have read and understood this Privacy Policy and agree <br></br>to its terms.
            </p>
          </div>
          </div>
         
    </div>
  )
}

export default PrivacyPolicy
