import React, { createContext, useEffect, useState } from 'react';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    // Load cart from localStorage or initialize as an empty array
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    // Save cart to localStorage whenever it changes
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product) => {
    setCart((prevCart) => [...prevCart, product]);
  };


  const removeFromCart = (itemId) => {
  // First, remove the item from the wishlist state
  setCart((prevCartlist) => {
    const updatedCartList = prevCartlist.filter(item => item._id !== itemId); // Remove item from state
    
    // Update localStorage with the new wishlist after modifying the state
    localStorage.setItem('cart', JSON.stringify(updatedCartList));
    
    return updatedCartList; // Return the updated wishlist state
  });
};



  const clearCart = () => {
    setCart([]);
    localStorage.removeItem('cart'); // Clear cart in localStorage on logout
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
