// src/contexts/PriceStatusContext.js
import React, { createContext, useState, useEffect } from 'react';
import { BaseUrl } from './Url/BaseUrl';


// Create a context for the price status
export const PriceStatusContext = createContext();

// Create a provider component
export const PriceStatusProvider = ({ children }) => {
  const [priceStatus, setPriceStatus] = useState({
    success: false,
    showPrice: false,
  });

  // Fetch data from the API when the provider mounts
  useEffect(() => {
    const fetchPriceStatus = async () => {
      try {
        const response = await fetch(`${BaseUrl}/api/GetPriceStatus`);
        const data = await response.json();
        setPriceStatus(data);
      } catch (error) {
        console.error('Error fetching price status:', error);
      }
    };

    fetchPriceStatus();
  }, []);

  return (
    <PriceStatusContext.Provider value={priceStatus}>
      {children}
    </PriceStatusContext.Provider>
  );
};
