import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import Testimonailauserimg from '../../asset/Testimonailauserimg.png';
import testimonalbg from '../../asset/testimonalbg.png';
import Testmonialsimage from '../../asset/Testmonialsimage.png';
import Wheateimg from '../../asset/wheateImage.png';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';

function Testimonial() {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(`${BaseUrl}/GetTestimonials`);
        const data = await response.json();
        setTestimonialsData(data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const currentTestimonial = testimonialsData[currentIndex];

  return (
    <div
      style={{
        backgroundImage: `url(${testimonalbg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '622px',
        display: 'flex',
        color: '#fff',
        textAlign: 'center',
      }}
      className='md:mt-[300px] justify-center flex gap-10'
    >
      <div className="flex items-center gap-[10px] ml-[280px]">
        <div>
          <img src={Testmonialsimage} alt="Testimonials" className="w-[212px] h-[212px]" />
        </div>
        <div>
          <p className="flex items-center text-[24px] text-[#4C3F35]" style={{ fontFamily: 'Dancing Script, cursive', fontWeight: 700, lineHeight: '28.8px', textAlign: 'left' }}>
            <img src={Wheateimg} alt="Wheat" className="w-[41.43px] h-[30px] mr-2" />
            Testimonials
          </p>
          <p className="text-[42px] font-bold text-[#222222] text-start" style={{ fontFamily: 'Lexend', fontWeight: 700 }}>
            Our Customer Feedback
          </p>

          {/* Set a min-height to maintain layout */}
          <div style={{ minHeight: '100px' }}>
            {currentTestimonial && (
              <p
                className="text-[24px] italic font-normal leading-[40px] text-left text-[#6D6D6D]"
                style={{ fontFamily: 'DM Sans' }}
                dangerouslySetInnerHTML={{ __html: currentTestimonial.review }}
              />
            )}
          </div>

          <div className='flex mt-10'>
            <div className='flex justify-between gap-72'>
              <div className='flex gap-5 '>
                <div>
                  <img src={Testimonailauserimg} alt={currentTestimonial?.shopName || 'User'} className="w-[79px] h-[79px] mr-2" />
                </div>
                <div >
                 <p
  className="text-[30px] font-medium   text-[#222222] w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-left"
  style={{ fontFamily: 'Lexend' }}
>
  {currentTestimonial?.shopName || 'User Name'}
</p>

                  <p className="text-[#4C3F35] text-[18px] font-normal leading-[23.44px] text-left" style={{ fontFamily: 'DM Sans' }}>
                    {currentTestimonial?.personName || 'User Role'}
                  </p>
                </div>
              </div>
              <div className='flex gap-2'>
                <button className='bg-[#F4D29A] text-black p-2 rounded-full w-[44px] h-[44px] flex justify-center items-center' onClick={handlePrevClick}>
                  <FaArrowLeft />
                </button>
                <button className='bg-[#F4D29A] text-black p-2 rounded-full w-[44px] h-[44px] flex justify-center items-center' onClick={handleNextClick}>
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
