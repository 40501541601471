import React, { useEffect, useRef, useState } from 'react';
import counbg from '../../asset/counbg.png';
import countimg1 from '../../asset/countimg1.png';
import countimg2 from '../../asset/countimg2.png';
import countimg3 from '../../asset/countimg3.png';
import countimg4 from '../../asset/countimg4.png';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';

function Count() {
  const [isVisible, setIsVisible] = useState(false);
  const [countData, setCountData] = useState([
    { image: countimg1, count: 0, label: "Our Total Products" },
    { image: countimg2, count: 0, label: "Team Members" },
    { image: countimg3, count: 0, label: "Satisfied Customers" },
    { image: countimg4, count: 0, label: "Awards Winning" },
  ]);
  const [countValues, setCountValues] = useState([0, 0, 0, 0]);
  const ref = useRef();

  useEffect(() => {
    // Fetch the count data from the API
    const fetchCountData = async () => {
      try {
        const response = await fetch(`${BaseUrl}/counts`);
        const data = await response.json();

        // Update the countData with API response
        setCountData([
          { image: countimg1, count: data.totalProducts, label: "Our Total Products" },
          { image: countimg2, count: data.teamMembers, label: "Team Members" },
          { image: countimg3, count: data.satisfiedCustomers, label: "Satisfied Customers" },
          { image: countimg4, count: data.awardsWon, label: "Awards Winning" },
        ]);
      } catch (error) {
        console.error('Error fetching count data:', error);
      }
    };

    fetchCountData();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisible(true);
        observer.disconnect(); // Stop observing once visible
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const newCountValues = countData.map((data, index) => {
        let count = 0;
        const targetCount = data.count;
        const duration = 2000; // Duration of the count animation in ms
        const incrementTime = Math.floor(duration / targetCount);
        
        const interval = setInterval(() => {
          if (count < targetCount) {
            count++;
            setCountValues((prev) => {
              const newCounts = [...prev];
              newCounts[index] = count;
              return newCounts;
            });
          } else {
            clearInterval(interval);
          }
        }, incrementTime);
        
        return () => clearInterval(interval);
      });

      return () => {
        newCountValues.forEach(clearInterval);
      };
    }
  }, [isVisible, countData]);

  return (
    <div
      ref={ref}
     className='hidden gap-2 mt-10 md:flex md:justify-center md:items-center'
      style={{
        backgroundImage: `url(${counbg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '30vh',
      }}
    >
      <div className='flex items-center gap-5'>
        {countData.map((data, index) => (
          <div key={index} className='flex items-center'>
            <img src={data.image} alt="Product" className="w-[64px] h-[64px] mr-2" />
            <div>
              <p className='font-lexend text-[42px] font-bold leading-[50px] text-left text-white'>
                {countValues[index]}+
              </p>
              <p className='text-white text-[18px]'>{data.label}</p>
            </div>
            {index < countData.length - 1 && <div className='h-[100px] w-[1px] bg-white ml-2' />} {/* Vertical Divider */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Count;
