import React, { useEffect, useState } from 'react';
import Wheateimg from '../../asset/wheateImage.png';
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';

function BestDeal() {
  const [deals, setDeals] = useState([]);
const navigate = useNavigate();
  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await fetch(`${BaseUrl}/GetDealsProduct`);
        const data = await response.json();
        setDeals(data.deals); // Assuming the response is structured as you mentioned
      } catch (error) {
        console.error('Error fetching deals:', error);
      }
    };

    fetchDeals();
  }, []);
 const NavigateToShopDetails = async (productId) => {
  if (productId) {
    try {
      const response = await fetch(`${BaseUrl}/products/GetSingleproductsForEcommerce`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productId }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // setProductDetails(data.SingleProductList);

      // Navigate to ShopDetails with the product details
      navigate("/ShopDetails", { state: { productId, productDetails: data.SingleProductList } });
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  }
};
  return (
    <div className='bg-[#4C3F35] md:h-[513px] md:mb-[300px]'>
      <div className='flex justify-center'>
        <p className="flex items-center text-[24px] text-[#C6A26F] mt-20" style={{ fontFamily: 'Dancing Script, cursive', fontWeight: 700, lineHeight: '28.8px', textAlign: 'left' }}>
          <img src={Wheateimg} alt="Wheat" className="w-[41.43px] h-[30px] mr-2" />
          Best Deals
        </p>
      </div>
      <div className='flex justify-center'>
        <p style={{ fontFamily: 'Lexend', fontSize: '42px', fontWeight: 700, lineHeight: '50px',  }} className='text-center text-white'>
          Best Deals Of The Week!
        </p>
      </div>

     <div className="grid justify-center gap-5 mt-10 md:mb-10 md:mt-24 md:flex ">
  {deals?.map(deal => (
    <div
      key={deal._id}
      className="w-[372px] p-4 bg-white rounded-[38px] shadow-md h-[459px] transition-transform duration-300 hover:scale-105" // Added transition and scale effect
    >
      <div className='bg-[#FFF9F0] h-[241px] w-[336px] flex justify-center items-center rounded-[30px]'>
        <img src={deal.selectedImages[0]} alt="Product" className="w-[173px] h-[173px] mr-2" />
      </div>
      <p
        style={{
          fontFamily: "DM Sans, sans-serif",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "18.23px",
        }}
        className="mt-2 text-[#6D6D6D] text-center"
      >
        {deal.category}
      </p>
      <div className='flex justify-center mt-5'>
        <h2
          className="mt-2"
          style={{
            fontFamily: 'Lexend',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '50px',
            textAlign: 'left',
          }}
        >
          {deal.productName.length > 20 ? `${deal.productName.substring(0, 20)}...` : deal.productName}
        </h2>
      </div>
      <div className="flex justify-center gap-2">
        <p
          style={{
            fontFamily: "DM Sans, sans-serif",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "18.23px",
          }}
          className="text-[#6D6D6D] text-center"
        >
          {deal.discount ? (
            <del>₹{deal.price.toFixed(2)}</del>
          ) : (
            `₹${deal.price.toFixed(2)}`
          )}
        </p>
        {deal.discount && (
          <p
            style={{
              fontFamily: "DM Sans, sans-serif",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "18.23px",
            }}
            className="text-[#6D6D6D] text-center"
          >
            ₹{(deal.price * (1 - deal.discount / 100)).toFixed(2)}
          </p>
        )}
        {deal.discount && (
          <button
            style={{
              fontFamily: "DM Sans, sans-serif",
              fontSize: "14px",
              fontWeight: 700,
              textAlign: "left",
              position: "relative",
              top: "-5px",
            }}
            className="bg-[#735944] text-white p-1 rounded-lg"
          >
            {`${deal.discount}% OFF`}
          </button>
        )}
      </div>
     <div className='relative flex justify-center mt-5'>
  <button
    className="w-[118px] h-[41px] bg-[#FF9F00] text-white rounded-[50px] p-2 relative overflow-hidden" // Added overflow-hidden
    onClick={() => NavigateToShopDetails(deal._id)} // pass the productId here
  >
    SHOP NOW
    <span className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-60 transform translate-x-[-150%] hover:translate-x-[150%] transition-transform duration-300 animate-shine"></span>
  </button>
</div>

    </div>
  ))}
</div>

    </div>
  );
}

export default BestDeal;
