import React from 'react';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import AboutBg from '../../asset/AboutBg.png';
import Aboutimagebg2 from '../../asset/Aboutimagebg2.png';
import AboutourCOmpany1 from '../../asset/AboutourCOmpany1.png';
import AboutourCOmpany2 from '../../asset/AboutourCOmpany2.png';
import AboutourCOmpany3 from '../../asset/AboutourCOmpany3.png';
import imgabout1 from '../../asset/imgabout1.png';
import imgabout2 from '../../asset/imgabout2.png';
import Wheateimg from '../../asset/wheateImage.png';
import AboutCount from '../../Componenet/AboutPage_Component/AboutCount';
import AboutTestinomials from '../../Componenet/AboutPage_Component/AboutTestinomials';
import How_we_make_Quaility from '../../Componenet/AboutPage_Component/How_we_make_Quaility';
import Why_Choose_Us from '../../Componenet/HomePage_Component.jsx/Why_Choose_Us';
import Count from '../../Componenet/HomePage_Component.jsx/Count';
import { Link } from 'react-router-dom';
import AboutImageChnage from "../../asset/AboutImageChnage.png";
function About() {
    return (
      <>
       <div
      className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4 "
      style={{ backgroundImage: `url(${AboutBg})` }}
    >
      <h1
        className="text-[#222222]  "
        style={{
          fontFamily: 'Lexend',
          fontSize: '50px',
          fontWeight: 500,
          lineHeight: '40px',
          textAlign: 'left',
              }}

      >
        About Us
      </h1>
      <p className="mt-4 text-lg ">Home &gt;<span className='text-[#BE740A]'> About Us</span></p>
    </div>
           <div className="flex flex-col justify-center gap-10 p-10 md:pl-[200px] md:flex-row">
  <div className="flex items-center justify-center md:mt-32">
    <img
      src={AboutImageChnage}
      alt="Wheat"
      className="  md:w-[500px] md:h-[500px] mr-2"
    />
  </div>
  <div className="flex-1">
    <p
      className="flex items-center text-[24px] text-[#4C3F35]"
      style={{
        fontFamily: "Dancing Script, cursive",
        fontWeight: 700,
        lineHeight: "28.8px",
        textAlign: "left",
      }}
    >
      <img
        src={Wheateimg}
        alt="Wheat"
        className="w-[41.43px] h-[30px] mr-2"
      />
      About Our Company
    </p>
    <p
      className="mt-5"
      style={{
        fontFamily: "Lexend",
        fontSize: "42px",
        fontWeight: 700,
        lineHeight: "50px",
        textAlign: "left",
      }}
    >
      Eating Right Starts With <br /> Organic Food
    </p>
    <p className="mt-5 text-[16px] md:text-[18px]">
      Organic foods are produced through a farming system that avoids the
      <br />
      use of synthetic pesticides, herbicides, genetically modified
      organisms (GMOs), and artificial fertilizers. Instead, organic farmers
      rely on natural methods like crop rotation, composting, and biological
      pest control.
    </p>
    <div className="flex flex-wrap gap-2 mt-10 ">
      <div>
        <img
          src={imgabout1}
          alt="Wheat"
          className="w-full md:w-[236px] h-auto md:h-[59px] rounded-lg"
        />
      </div>
      <div className="mt-5 md:block md:mt-0">
        <img
          src={imgabout2}
          alt="Wheat"
          className="w-full md:w-[236px] h-auto md:h-[59px] rounded-lg"
        />
      </div>
    </div>
    <hr className="mt-10" />
    <div className="mt-5">
      <Link
        to="/Shop"
        className="flex bg-[#735944] text-white p-2 h-[54px] w-full md:w-[187px] justify-center gap-2 rounded-full items-center hover:scale-110 transition-transform duration-300"
      >
        DISCOVER MORE{" "}
        <MdKeyboardDoubleArrowRight className="w-[25.48px] h-[24.48px]" />
      </Link>
    </div>
  </div>
</div>

            <div>
                <How_we_make_Quaility />
            </div>
            <div>
          {/* <AboutCount /> */}
          <Count />
            </div>
            <div>
                <Why_Choose_Us />
            </div>
            <div>
                <AboutTestinomials />
            </div>
           
      </>
   
  );
}

export default About;
