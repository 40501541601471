import React, { useContext, useState } from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { MdOutlineShoppingCart, MdDelete } from "react-icons/md";
import AboutBg from '../../asset/AboutBg.png';
import { CartContext } from '../../ContextApi/AddtocartContext';
import { WishlistContext } from '../../ContextApi/Context';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';
import { useNavigate } from 'react-router-dom';
import emptywishlist from '../../asset/emptywishlist.png';
import Swal from 'sweetalert2';

function WishListPage() {
  const { wishlist, removeFromWishlist } = useContext(WishlistContext);
  const { cart, addToCart } = useContext(CartContext);
  const navigate = useNavigate();
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8; // Number of products to display per page

  const totalProducts = wishlist.length; // Total number of products
  const totalPages = Math.ceil(totalProducts / productsPerPage); // Total number of pages

  // Get current products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = wishlist.slice(indexOfFirstProduct, indexOfLastProduct);

  const NavigateToShopDetails = async (productId) => {
    if (productId) {
      try {
        const response = await fetch(`${BaseUrl}/products/GetSingleproductsForEcommerce`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ productId }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        navigate("/ShopDetails", { state: { productId, productDetails: data.SingleProductList } });
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    }
  };

  const handleAddToCart = (product) => {
    const productInCart = cart.find(item => item._id.toString() === product._id.toString());
    if (productInCart) {
      alert('Product already available in Cart');
    } else {
      addToCart(product);
      alert('Product Added in Cart');
      removeFromWishlist(product._id); // Remove from wishlist
    }
  };

  const calculateDiscountedPrice = (price, discountPercentage) => {
    return price - (price * discountPercentage / 100);
  };

  const HandleRemovefromWishlist = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to remove this product from your wishlist?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        removeFromWishlist(id);
        Swal.fire({
          icon: 'success',
          title: 'Removed!',
          text: 'The product has been removed from your wishlist.',
          showConfirmButton: false,
          timer: 1500
        });
      }
    });
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    // <div>
    //   <div
    //     className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4"
    //     style={{ backgroundImage: `url(${AboutBg})` }}
    //   >
    //     <h1
    //       className="text-[#222222]"
    //       style={{
    //         fontFamily: 'Lexend',
    //         fontSize: '50px',
    //         fontWeight: 500,
    //         lineHeight: '40px',
    //         textAlign: 'left',
    //       }}
    //     >
    //       Wishlist
    //     </h1>
    //     <p className="mt-4 text-lg">Home &gt;<span className='text-[#BE740A]'>Wishlist</span></p>
    //   </div>

    //   <div className='flex pl-48 mt-20'>
    //     <p
    //       style={{
    //         fontFamily: 'Lexend',
    //         fontSize: '42px',
    //         fontWeight: '700',
    //         lineHeight: '40px',
    //         textAlign: 'left',
    //       }}
    //     >
    //       Wishlist
    //     </p>
    //   </div>

    //   <div className='flex justify-center mt-5'>
    //     <div className="overflow-x-auto">
    //       <table className=" -collapse w-[1156px]">
    //         <thead className='h-[54px]'>
    //           <tr className="bg-[#F2F2F2] border">
    //             <th className="p-2 border-2">Product Image</th>
    //             <th className="p-2 border-2">Product Name</th>
    //             <th className="p-2 border-2">MRP/kg</th>
    //             <th className="p-2 border-2">Date Added</th>
    //             <th className="p-2 border-2">Action</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {currentProducts.length === 0 ? (
    //             <tr>
    //               <td colSpan={5} className="p-4 text-center">
    //                 <div className='flex justify-center item-center'>
    //                   <img src={emptywishlist} alt="Wheat" className="h-[200px] w-[150px]" />
    //                 </div>
    //                 <p className=" text-gray-600 font-bold text-[20px] mt-2">No products in the wishlist.</p>
    //               </td>
    //             </tr>
    //           ) : (
    //             currentProducts.map(item => (
    //               <tr key={item.id} className="text-center border h-[76px]">
    //                 <td className="flex justify-center p-2">
    //                   <img src={item.selectedImages[0]} alt={item.productName} className="w-[50px] h-[50px] mr-2 rounded-lg" />
    //                 </td>
    //                 <td className="p-2 border-2 cursor-pointer hover:underline" onClick={() => NavigateToShopDetails(item._id)}>{item.productName}</td>
    //                 <td className="p-2 border-2">
    //                   <div className="flex justify-center gap-2">
    //                     <p
    //                       style={{
    //                         fontFamily: "DM Sans, sans-serif",
    //                         fontSize: "14px",
    //                         fontWeight: 700,
    //                         lineHeight: "18.23px",
    //                       }}
    //                       className="text-[#6D6D6D] text-center"
    //                     >
    //                       {item.discount ? (
    //                         <>
    //                           <del>₹{item.price.toFixed(2)}</del> <span className='text-gray-800'>₹{calculateDiscountedPrice(item.price, item.discount).toFixed(2)}</span>
    //                         </>
    //                       ) : `₹${item.price.toFixed(2)}`}
    //                     </p>
    //                   </div>
    //                 </td>
    //                 <td className="p-2 border-2">{new Date(item.dateAdded).toLocaleDateString()}</td>
    //                 <td className="flex justify-center gap-10 p-2 text-center">
    //                   <button
    //                     className="flex items-center justify-center text-red-500 hover:text-red-700"
    //                     onClick={() => HandleRemovefromWishlist(item._id)}
    //                   >
    //                     <MdDelete className="w-5 h-5" />
    //                   </button>
    //                 </td>
    //               </tr>
    //             ))
    //           )}
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>

    //   {/* Pagination Controls */}
    //   <div className='flex justify-center pl-5'>
    //      <div className='flex gap-5 mt-5 mb-20 '>
    //     <button
    //       onClick={handlePreviousPage}
    //       disabled={currentPage === 1}
    //       className={`bg-[#BE740A] text-white px-4 py-2 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
    //     >
    //       Previous
    //     </button>
    //     <span className='mt-2'>{`Page ${currentPage} of ${totalPages}`}</span>
    //     <button
    //       onClick={handleNextPage}
    //       disabled={currentPage === totalPages}
    //       className={`bg-[#BE740A] text-white px-4 py-2 rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
    //     >
    //       Next
    //     </button>
    //   </div>
    //   </div>
     
    // </div>
    <div className="px-4 md:px-0">
  <div
    className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4"
    style={{ backgroundImage: `url(${AboutBg})` }}
  >
    <h1
      className="text-[#222222] text-4xl md:text-5xl"
      style={{
        fontFamily: 'Lexend',
        fontWeight: 500,
        lineHeight: '40px',
        textAlign: 'left',
      }}
    >
      Wishlist
    </h1>
    <p className="mt-4 text-lg">
      Home &gt; <span className="text-[#BE740A]">Wishlist</span>
    </p>
  </div>

  <div className="flex justify-center mt-20">
    <p
      className="text-3xl font-bold md:text-4xl"
      style={{ fontFamily: 'Lexend' }}
    >
      Wishlist Product
    </p>
  </div>

  <div className="flex justify-center mt-5">
    <div className="w-full max-w-4xl overflow-x-auto">
      <table className="w-full table-auto">
        <thead className="h-[54px]">
          <tr className="bg-[#F2F2F2] border">
            <th className="p-2 border-2">Product Image</th>
            <th className="p-2 border-2">Product Name</th>
            <th className="p-2 border-2">MRP/kg</th>
            <th className="p-2 border-2">Date Added</th>
            <th className="p-2 border-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentProducts.length === 0 ? (
            <tr>
              <td colSpan={5} className="p-4 text-center">
                <div className="flex items-center justify-center">
                  <img src={emptywishlist} alt="Wheat" className="h-[200px] w-[150px]" />
                </div>
                <p className="text-gray-600 font-bold text-[20px] mt-2">No products in the wishlist.</p>
              </td>
            </tr>
          ) : (
            currentProducts.map(item => (
              <tr key={item.id} className="text-center border h-[76px]">
                <td className="flex justify-center p-2">
                  <img src={item.selectedImages[0]} alt={item.productName} className="w-[50px] h-[50px] mr-2 rounded-lg" />
                </td>
                <td className="p-2 border-2 cursor-pointer hover:underline" onClick={() => NavigateToShopDetails(item._id)}>
                  {item.productName}
                </td>
                <td className="p-2 border-2">
                  <div className="flex justify-center gap-2">
                    <p
                      style={{
                        fontFamily: "DM Sans, sans-serif",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18.23px",
                      }}
                      className="text-[#6D6D6D] text-center"
                    >
                      {item.discount ? (
                        <>
                          <del>₹{item.price.toFixed(2)}</del> <span className='text-gray-800'>₹{calculateDiscountedPrice(item.price, item.discount).toFixed(2)}</span>
                        </>
                      ) : `₹${item.price.toFixed(2)}`}
                    </p>
                  </div>
                </td>
                <td className="p-2 border-2">{new Date(item.dateAdded).toLocaleDateString()}</td>
                <td className="flex justify-center gap-10 p-2 text-center">
                  <button
                    className="flex items-center justify-center text-red-500 hover:text-red-700"
                    onClick={() => HandleRemovefromWishlist(item._id)}
                  >
                    <MdDelete className="w-5 h-5" />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  </div>

  {/* Pagination Controls */}
  <div className="flex justify-center pl-5">
    <div className="flex gap-5 mt-5 mb-20">
      <button
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        className={`bg-[#BE740A] text-white px-4 py-2 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        Previous
      </button>
      <span className='mt-2'>{`Page ${currentPage} of ${totalPages}`}</span>
      <button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        className={`bg-[#BE740A] text-white px-4 py-2 rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        Next
      </button>
    </div>
  </div>
</div>

  );
}

export default WishListPage;
