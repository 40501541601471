import React from 'react';
import { ClipLoader } from 'react-spinners';

const Loader = ({ loading,message }) => {
  if (!loading) return null; // Don't render if not loading

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="flex flex-col items-center">
        <ClipLoader color="white" loading={loading} size={100} />
        <p className="mt-4 text-2xl text-white">{message}</p>
      </div>
    </div>
  );
};

export default Loader;
