import React, { useContext, useEffect, useRef, useState } from 'react';
import { CiHeart, CiSearch } from "react-icons/ci";
import { FaFacebookF, FaInstagram, FaRegUser, FaWhatsapp } from "react-icons/fa";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { IoMdLogOut } from "react-icons/io";
import { IoCartOutline } from "react-icons/io5";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import AakaarLogo from "../../asset/AakaarLogo.png";
import UserLogin from '../../asset/UserLogin.png';
import DrawerModal from '../../Componenet/CartModel';
import LoginRegisterModel from '../../Componenet/Models/LoginRegisterModel';
import { CartContext } from '../../ContextApi/AddtocartContext';
import { WishlistContext } from '../../ContextApi/Context';
import UserMenu from './UserMenu';
function Header() {
  const [ismodelOpen, setIsModelOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const navigate = useNavigate();
  const { cart, removeFromCart } = useContext(CartContext);
  const { wishlist, removeFromWishlist } = useContext(WishlistContext);
   
  const [dealerInfo, setDealerInfo] = useState(null);
  const [heartCountData, setHeartCount] = useState(0);
  const [whishlistdata, setwhishlistdata] = useState([]);
  const [DealerInformation, setDealerInformation] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
   const [randomPlaceholder, setRandomPlaceholder] = useState('');

 const categories = ['Grains', 'Wheat', 'Rice', 'Corn', 'Barley', 'Oats'];
   const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowUserMenu(false); // Close the menu if clicked outside
    }
  };

  useEffect(() => {
    // Adding event listener to detect clicks outside the modal
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    let interval;

    if (!searchTerm) {
      const updatePlaceholder = () => {
        const randomCategory = categories[Math.floor(Math.random() * categories.length)];
        setRandomPlaceholder(randomCategory);
      };

      // Set an interval to change the random category every 3 seconds
      updatePlaceholder(); // Initial call to set the first placeholder
      interval = setInterval(updatePlaceholder, 3000); // Change every 3 seconds
    }

    // Clear the interval when the component unmounts or when the user types something
    return () => clearInterval(interval);
  }, [searchTerm]);

    const handleSearch = () => {
    // Navigate to /Shop without appending search query to the URL
    navigate('/Shop', { state: { searchTerm } });
  };
useEffect(() => {
        // Check for token in localStorage
        const token = localStorage.getItem('authtoken');
        if (token) {
            setIsLogin(true); // Set isLogin to true if token exists
        } else {
            setIsLogin(false); // Set isLogin to false if token does not exist
        }
}, []);
 useEffect(() => {
    const storedDealerInfo = localStorage.getItem('dealerInfo');
    if (storedDealerInfo) {
      setDealerInfo(JSON.parse(storedDealerInfo)); // Parse the string to JSON
    }
  }, []);
  useEffect(() => {
    // Retrieve wishlist data from localStorage
    const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setwhishlistdata(storedWishlist)
    setHeartCount(storedWishlist.length); // Set heartCount to the length of the wishlist
  }, []);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const heartCount = 5; // Example count for heart icon
  const cartCount = 3; // Example count for cart icon

  const HandleClickOnLogin = () => {
     setIsModelOpen(true);
    // setIsLogin(true);
  };

  const HandleClickOffLogin = () => {
    setIsModelOpen(false);
  };

  const HandleClickOnUserIsLogin = () => {
    setIsLogin(true); 
  };

  const openModal = () => {
    setIsCartModalOpen(true);
  };

  const closeModal = () => {
    setIsCartModalOpen(false);
  };

  const handleLogout = () => {
       setShowUserMenu(false);
    Swal.fire({
        title: 'Are you sure?',
        text: 'You will be logged out of your account.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, log me out',
        cancelButtonText: 'Cancel',
    }).then((result) => {
        if (result.isConfirmed) {
            // If user confirms, proceed with the logout
            localStorage.clear(); // Remove all data from localStorage
            setIsLogin(false);
            setShowUserMenu(false); // Close the menu on logout
            navigate('/'); // Redirect to home or login page
            window.location.reload(); // Reload the page

            // Show a success message after logout
            Swal.fire({
                icon: 'success',
                title: 'Logged out',
                text: 'You have been successfully logged out.',
                confirmButtonText: 'OK'
            });
        }
    });
};


  const NavigateToMyOrders = () => {
    setShowUserMenu(false);
    navigate('/MyOrders');
  };

  const NavigateToProfile = () => {
    setShowUserMenu(false);
    navigate('/MyProfile');
  };

  const handleUserIconClick = () => {
    setShowUserMenu(!showUserMenu);
  };

  return (
    <>
      <div className='bg-[#C6A26F] h-[40px] flex items-center justify-end pr-4 md:pr-32'>
        <div className='flex justify-end w-full gap-4 md:gap-10'>
          <span className='text-white'><FaFacebookF /></span>
          <span className='text-white'><FaInstagram /></span>
          <span className='text-white'><FaWhatsapp /></span>
        </div>
      </div>

      <div className='flex flex-col items-center justify-center gap-4 p-2 bg-white md:flex-row md:gap-20'>
        <img src={AakaarLogo} alt="Aakaar Logo" className="h-[60px] md:h-[80px] w-auto" />

        <nav className='flex gap-4 md:gap-8'>
          <NavLink 
            to="/" 
            className={({ isActive }) => isActive ? 'text-black text-[16px] font-semibold border-b-2 border-[#C6A26F]' : 'text-black text-[16px] font-semibold'}
          >
            HOME
          </NavLink>
          <NavLink 
            to="/About" 
            className={({ isActive }) => isActive ? 'text-black text-[16px] font-semibold border-b-2 border-[#C6A26F]' : 'text-black text-[16px] font-semibold'}
          >
            ABOUT US
          </NavLink>
          <NavLink 
            to="/Shop" 
            className={({ isActive }) => isActive ? 'text-black text-[16px] font-semibold border-b-2 border-[#C6A26F]' : 'text-black text-[16px] font-semibold'}
          >
            SHOP
          </NavLink>
          <NavLink 
            to="/Contact" 
            className={({ isActive }) => isActive ? 'text-black text-[16px] font-semibold border-b-2 border-[#C6A26F]' : 'text-black text-[16px] font-semibold'}
          >
            CONTACT US
          </NavLink>
        </nav>
             
        <div className="flex items-center overflow-hidden border border-gray-300 rounded-full">
          <input
      type="text"
      placeholder={searchTerm ? '' : `Search for ${randomPlaceholder}...`}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="w-full px-4 py-2 focus:outline-none"
    />
      <button onClick={handleSearch} className="px-4 py-2 text-black">
        <CiSearch className="h-[28px] w-[28px]" />
      </button>
        </div>
        
        <div className='relative flex gap-4 md:gap-5'>
          <Link className='relative' to='/Wishlist'>
      <CiHeart className='h-[28px] w-[28px] font-[300]' />
      {heartCount > 0 && (
        <span className='absolute top-[-5px] right-[-5px] bg-red-500 text-white text-xs rounded-full px-1'>
          {wishlist.length}
        </span>
      )}
    </Link>
          <span className='relative' onClick={openModal}>
            <IoCartOutline className='h-[28px] w-[28px] cursor-pointer' />
            {cartCount > 0 && (
              <span className='absolute top-[-5px] right-[-10px] bg-red-500 text-white text-xs rounded-full px-1'>
                {cart.length}
              </span>
            )}
          </span>
          {/* {dealerInfo && (
            <>
              <span className='relative cursor-pointer' onClick={handleUserIconClick}>
                <FaRegUser className='h-[24px] w-[24px]' />
              </span>

              {showUserMenu && (
                <div className="absolute right-0 mt-10 bg-white shadow-lg w-[230px] h-auto rounded-lg">
                  <div className="flex justify-center gap-2 p-4 font-bold">
                    <img src={UserLogin} alt="User" className="w-[45px] h-[45px] mr-2 rounded-lg" />
                    <div>
                      <p>{ dealerInfo.shopName}</p>
                     <p className='text-[#6D6D6D]'>
  {dealerInfo.email?.length > 10 ? `${dealerInfo.email.substring(0, 10)}...` : dealerInfo.email}
</p>
                    </div>
                  </div>
                  <hr className='border-1' />
                  <p className='flex justify-start gap-2 p-5 font-medium text-left cursor-pointer text-20px leading-12 hover:bg-gray-200' onClick={NavigateToMyOrders}>
                    <HiOutlineShoppingBag className='mt-1.5' />
                    <span>My Orders</span>
                  </p>
                  <hr className='border-1' />
                  <p className='flex justify-start gap-2 p-5 font-medium text-left cursor-pointer text-20px leading-12 hover:bg-gray-200' onClick={NavigateToProfile}>
                    <FaRegUser className='mt-1.5' />
                    <span>My Profile</span>
                  </p>
                  <hr className='border-1' />
                  <p className='flex justify-start gap-2 p-5 font-medium text-left cursor-pointer text-20px leading-12 hover:bg-gray-200' onClick={handleLogout}>
                    <IoMdLogOut className='mt-1.5' />
                    <span className='text-red-500'>Logout</span>
                  </p>
                </div>
              )}
            </>
          )} */}
          <UserMenu dealerInfo={dealerInfo} NavigateToMyOrders={NavigateToMyOrders} NavigateToProfile={NavigateToProfile} handleLogout={handleLogout} UserLogin={UserLogin} />
        </div>
        
        {!dealerInfo && (
          <div className='flex justify-center'>
            <button className='flex bg-[#735944] text-white p-2 h-[51px] w-[162px] justify-center gap-2 rounded-full items-center' onClick={HandleClickOnLogin}>
              Login 
            </button>
          </div>
        )}
      </div>
      <LoginRegisterModel isModalOpen={ismodelOpen} onClose={HandleClickOffLogin} />
      <DrawerModal isOpen={isCartModalOpen} onClose={closeModal} />
    </>
  );
}

export default Header;
